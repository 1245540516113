import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

/**
 * A CacheOptions object tells how long a item should be in the cache.
 *
 * @interface CacheOptions
 */
interface CacheOptions {
  expires?: number;
  maxAge?: number;
}

/**
 * A StorageObject is the value-to-store plus a CacheOptions object.
 *
 * @interface StorageObject
 */
interface StorageObject {
  value: any;
  options: CacheOptions;
}

/**
 * Use this service to set items into a key-value cache store with support for item expiry.
 *
 * @export
 * @class CacheService
 */
@Injectable({
  providedIn: 'root',
})
export class CacheService {
  // Default prefix. This could be removed when all brands have their own domain but
  // for now it's handy for local development.
  public prefix = 'com';

  // By default, items don't expire
  private defaultOptions: CacheOptions = {
    expires: Number.MAX_VALUE,
    maxAge: Number.MAX_VALUE,
  };

  constructor(@Inject(PLATFORM_ID) private platformId: any) {}

  /**
   * Checks if an object is (already) stored in a cache
   *
   * @param {string} key
   * @param {('local' | 'session')} [storageType]
   * @returns {boolean}
   * @memberof CacheService
   */
  public exists(key: string, storageType?: 'local' | 'session'): boolean {
    return !!this.get<any>(key, storageType);
  }

  /**
   * Retrieves an object from cache
   *
   * @param {string} key
   * @param {('local' | 'session')} [storageType]
   * @returns {*}
   * @memberof CacheService
   */
  public get<C>(key: string, storageType?: 'local' | 'session'): C {
    // Start with value = null
    let value: any = null;
    if (isPlatformBrowser(this.platformId)) {
      // which storage (local or session) are we using?
      const storage = storageType === 'session' ? sessionStorage : localStorage;
      // Get the item stored with the key and parse it
      const storageValue = JSON.parse(storage.getItem(this.toStorageKey(key)));
      if (storageValue) {
        if (CacheService.validateStorageValue(storageValue)) {
          value = storageValue.value;
        } else {
          this.remove(key);
        }
      }
    }
    return value as C;
  }

  /**
   * Saves an object to cache
   *
   * @param {string} key
   * @param {*} value
   * @param {('local' | 'session')} [storageType]
   * @param {CacheOptions} [options]
   * @returns
   * @memberof CacheService
   */
  public set(
    key: string,
    value: any,
    storageType?: 'local' | 'session',
    rawOptions?: CacheOptions
  ) {
    if (isPlatformBrowser(this.platformId)) {
      let options;
      if (rawOptions) {
        options = { ...rawOptions };
      } else {
        options = this.defaultOptions;
      }
      // which storage (local or session) are we using?
      const storage = storageType === 'session' ? sessionStorage : localStorage;

      const storageKey = this.toStorageKey(key);

      storage.setItem(
        storageKey,
        JSON.stringify(this.toStorageValue(value, options))
      );
      return true;
    }
    return false;
  }

  /**
   * Removes an object from cache
   *
   * @param {string} key
   * @param {('local' | 'session')} [storageType]
   * @memberof CacheService
   */
  public remove(key: string, storageType?: 'local' | 'session'): void {
    if (isPlatformBrowser(this.platformId)) {
      // which storage (local or session) are we using?
      const storage = storageType === 'session' ? sessionStorage : localStorage;
      storage.removeItem(this.toStorageKey(key));
    }
  }

  /**
   * Remove everything from cache (local- and sessionStorage!)
   *
   * @memberof CacheService
   */
  public clear(storageType?: 'local' | 'session' | null): void {
    if (isPlatformBrowser(this.platformId)) {
      if (storageType === null) {
        sessionStorage.clear();
        localStorage.clear();
      } else {
        const storage =
          storageType === 'session' ? sessionStorage : localStorage;
        storage.clear();
      }
    }
  }

  /**
   * Takes an key and makes it usable for storage
   *
   * @private
   * @param {string} key
   * @returns {string}
   * @memberof CacheService
   */
  private toStorageKey(key: string): string {
    return `${this.prefix}-${key}`;
  }

  /**
   * Takes an object to be cached and adds the caching metadata
   * Takes a possibly incomplete options object and completes it
   *
   * @private
   * @param {*} value
   * @param {CacheOptions} options
   * @returns {StorageObject}
   * @memberof CacheService
   */
  private toStorageValue(value: any, options: CacheOptions): StorageObject {
    const storageOptions: CacheOptions = {};
    // Was the expires option explicitly given? Than use that.
    // Otherwise calculate it using the maxAge options
    if (options.expires) {
      storageOptions.expires = options.expires;
    } else if (options.maxAge) {
      storageOptions.expires = Date.now() + options.maxAge * 1000;
    } else {
      storageOptions.expires = this.defaultOptions.expires;
    }

    storageOptions.maxAge = options.maxAge
      ? options.maxAge
      : this.defaultOptions.maxAge;

    return {
      value,
      options: storageOptions,
    };
  }

  /**
   * Takes an cached object and checks whether it has expired yet
   *
   * @private
   * @param {StorageObject} value
   * @returns {boolean}
   * @memberof CacheService
   */
  private static validateStorageValue(value: StorageObject): boolean {
    // Is there an expires option and is it larger than the current timestamp?
    return !!value.options.expires && value.options.expires > Date.now();
  }
}
