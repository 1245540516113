<div class="com-footer txt-white">
  <div class="u-width-100 bg-primary footer-top">
    <div class="scroll u-width-100 txt-center is-hidden-print">
      <a class="bg-primary icon-white" (click)="scrollToTop()">
        <i class="fas fa-arrow-up fa-fw icon-large "></i>
      </a>
    </div>
    <div class="grid">
      <div class="columns">
        <div class="col-12 col-md-6 left">
          <h3>{{ 'FOOTER.questions' | translate }}</h3>
          <ul class="contact">
            <li>
              <i class="fa fa-chevron-right"></i>
              <p [innerHTML]="'FOOTER.item1' | translate"></p>
            </li>
            <li>
              <i class="fa fa-chevron-right "></i>
              <p [innerHTML]="'FOOTER.item2' | translate"></p>
            </li>
            <li>
              <i class="fa fa-chevron-right "></i>
              <p [innerHTML]="'FOOTER.item3' | translate"></p>
            </li>
            <li>
              <i class="fa fa-chevron-right "></i>
              <p [innerHTML]="'FOOTER.item4' | translate"></p>
            </li>
          </ul>
          <div class="reachability ">
            <span [innerHTML]="'FOOTER.reachability' | translate"></span>
            <span [innerHTML]="'FOOTER.timeMon' | translate"></span>
            <span [innerHTML]="'FOOTER.timeRest' | translate"></span>
          </div>
        </div>
        <div class="col-12 col-md-6 right is-hidden-print">
          <h3 class="txt-white">{{ 'FOOTER.social' | translate }}</h3>
          <ul class="social">
            <a href="https://www.facebook.com/compassionnederland" target="_blank">
              <li class="bg-white">
                <i class="fab fa-fw icon-primary fa-facebook icon-footer"></i>
              </li>
            </a>
            <a href="https://twitter.com/compassion_NL" target="_blank">
              <li class="bg-white">
                <i class="fab fa-fw icon-primary fa-twitter icon-footer"></i>
              </li>
            </a>
            <a href="https://www.instagram.com/compassionnl/" target="_blank">
              <li class="bg-white">
                <i class="fab fa-fw icon-primary fa-instagram icon-footer"></i>
              </li>
            </a>
            <a href="https://www.linkedin.com/groups?gid=1847083" target="_blank">
              <li class="bg-white">
                <i class="fab fa-fw icon-primary fa-linkedin icon-footer"></i>
              </li>
            </a>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="u-width-100 footer-bottom is-hidden-print">
    <div class="grid">
      <div class="columns">
        <div class="col-12 content">
          <p>
            <span [innerHTML]="'FOOTER.privacyStatement' | translate"></span>
            {{ 'FOOTER.and' | translate }}
            <span [innerHTML]="'FOOTER.cookieStatement' | translate"></span>
          </p>
          <p>&copy;{{ 'FOOTER.copyright' | translate }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
