import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
/**
 * Checks wether the key is for a man or a woman
 *
 * @export
 * @class GenderTranslatePipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'genderTranslate',
  pure: false,
})
export class GenderTranslatePipe implements PipeTransform {
  /**
   * Creates an instance of GenderTranslatePipe.
   * @param {TranslateService} translateService
   * @memberof GenderTranslatePipe
   */
  constructor(private translateService: TranslateService) {}

  /**
   * Adds _male or _female to the key depending on the gender
   * Usage: 'key' | genderTranslate: true/false
   *  true = male, false is female
   *
   * @param {string} key
   * @param {boolean} isMale
   * @returns {string}
   * @memberof GenderTranslatePipe
   */
  transform(key: string, isMale: boolean): string {
    return this.translateService.instant((key += isMale ? '_male' : '_female'));
  }
}
