<div *ngIf="isLoading" class="loader">
  <app-loader color="blue"></app-loader>
</div>

<ng-template #alertpopup>
  <div class="alert-container">
    <h2>{{ alert.title | translate }}</h2>
    <p>{{ alert.message | translate }}</p>
    <div class="btn-container">
      <app-button *ngIf="alert.okButton" (buttonPressed)="clickButtonAlert(alert.okButton.handler)">
        {{ alert.okButton.text | translate }}</app-button>
      <app-button [cssClasses]="'is-primary'" *ngIf="alert.forgotButton"
        (buttonPressed)="clickButtonAlert(alert.forgotButton.handler)">
        {{ alert.forgotButton.text | translate }}</app-button>
    </div>
  </div>
</ng-template>