import { Component, OnInit, TemplateRef } from '@angular/core';
import { PopupService } from '../../services/popup.service';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
})
export class PopUpComponent implements OnInit {
  public isOpen: boolean = false;

  public template: TemplateRef<any> = undefined;

  constructor(private popupService: PopupService) {}

  public ngOnInit() {
    this.popupService.isOpen$.subscribe(state => {
      this.isOpen = state;
    });

    this.popupService.template$.subscribe(template => {
      this.template = template;
    });
  }
}
