import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../services/language/language.service';
/**
 * Footer
 *
 * @export
 * @class FooterComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  /**
   * List of available lanaguages
   *
   * @memberof FooterComponent
   */
  languages = [];

  /**
   *Creates an instance of FooterComponent.
   * @param {LanguageService} languageService
   * @memberof FooterComponent
   */
  constructor(private languageService: LanguageService) {}

  /**
   * called when initializing component
   */
  ngOnInit() {
    this.languages = LanguageService.getLanguages();
  }

  /**
   * Scrolls to top
   *
   * @memberof FooterComponent
   */
  scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  /**
   *For switching languages
   *
   * @memberof FooterComponent
   */
  selectLanguage(event) {
    this.languageService.setLanguage(event.value);
  }
}
