/**
 * @ignore for documentation
 */
export const environment = {
  apiUrl: 'https://mystage.compassion.nl/api/com/',
  mainUrl: 'https://mystage.compassion.nl/',
  production: false,
  apikey: 'yjtfghdfgsewrethn',
  features: {
    paymentsPage: true,
    correspondenceChanger: true,
    changeEmail: true,
    faqWidget: true,
    canDonate: true,
    changeContact: true,
    showTestimonials: true,
    loadPiwik: true,
    useDrafts: true,
  },
};
