import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CacheService } from '../cache.service';

/**
 * Key for storing language
 */
const LNG_KEY = 'language';
/**
 * Service for languages
 *
 * @export
 * @class LanguageService
 */
@Injectable({
  providedIn: 'root',
})
export class LanguageService implements OnDestroy {
  /**
   * Stores selected language
   *
   * @memberof LanguageService
   */
  selected = new BehaviorSubject<string>('');

  /**
   * Creates an instance of LanguageService.
   * @param {TranslateService} translate
   * @param {Storage} storage
   * @memberof LanguageService
   */
  constructor(
    private translate: TranslateService,
    private cacheService: CacheService
  ) { }

  /**
   * Needed for the use of Moment;
   *
   * @memberof ChildrenPage
   */
  moment = moment;

  /**
   * Array with all the descriptions to unsub from whe the page leaves
   *
   * @private
   * @type {Subscription[]}
   * @memberof LanguageService
   */
  private subscriptions: Subscription[] = [];

  /**
   * Gets browser language and sets it to local storage,
   * if browser language is know it will set it,
   * otherwise it will set 'nl' as the default language
   *
   * For mobile it check the storage and set the language if it known
   *
   * @memberof LanguageService
   */
  setInitialAppLanguage() {
    if (this.cacheService.exists(LNG_KEY)) {
      this.setLanguage(this.cacheService.get<string>(LNG_KEY));
    }

    const selected$ = this.selected.subscribe(val => {
      // Set global language for moment
      moment.locale(val);
      if (!val) {
        const language = 'nl';
        LanguageService.getLanguages().forEach(lang => {
          if (lang.value === language) {
            this.setLanguage(lang.value);
            this.translate.setDefaultLang(language);
          }
        });
      }
    });

    this.subscriptions.push(selected$);
  }

  /**
   * Here are the supported langues stored.
   * It will return array with language objects
   *
   * @returns {{ text: string; value: string }[]}
   * @memberof LanguageService
   */
  static getLanguages(): { text: string; value: string }[] {
    return [
      { text: 'Engels', value: 'en' },
      { text: 'Nederlands', value: 'nl' },
    ];
  }

  /**
   * Sets the language in local storage and mobile storage.
   *
   * @param {string} lng
   * @memberof LanguageService
   */
  setLanguage(lng: string) {
    this.translate.use(lng);
    this.selected.next(lng);
    this.cacheService.set(LNG_KEY, lng);
  }

  /**
   * Unsubscribe from the observables
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
