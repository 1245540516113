<div class="header is-hidden-print">
  <nav class="navbar grid" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <a class="navbar-item" [routerLink]="'/'">
        <img class="logo-desktop" alt="logo" height="67" src="assets/logo_payoff.svg" />
        <img class="logo-mobile" alt="logo" src="assets/logo_total.svg" />
      </a>
      <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" *ngIf="isLoggedIn"
        [ngClass]="{'is-active': isOpen}" (click)="toggleMenu()">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
    <div class="navbar-menu" [ngClass]="{'is-active': isOpen}">
      <div class="navbar-start" *ngIf="isLoggedIn">
        <a class="navbar-item" *ngFor="let nav of navItems" [routerLink]="nav.url" (click)='changeTo(nav.url, $event)'
          routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          {{ nav.name | translate }}
        </a>
      </div>
      <div class="navbar-end">
        <a class="navbar-item logout" *ngIf="isLoggedIn" (click)="logout()">
          <i class="fa fa-times txt-primary"></i> {{'LINK.logout' | translate}}
        </a>
        <div class="navbar-item">
          <app-button (buttonPressed)="navigate()">
            <div angulartics2On="click" angularticsAction="Navigation" angularticsCategory="External Links"
              class="txt-primary">
              {{'BUTTON.backToSite' | translate}}
            </div>
          </app-button>
        </div>
      </div>
    </div>
  </nav>

</div>
