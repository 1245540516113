import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { WPCampaign } from '../../interfaces/WPCampaign.interface';
import {
  BankId,
  DonationEvent,
  DonationFrequency,
  DonationSettings,
  DonationType,
} from '../../interfaces/donation.interface';
import { PaymentDetails } from '../../model/paymentprofile.model';
import { DateService } from '../../services/date.service';
import { HelperService } from '../../services/helper.service';
import { UserService } from '../../services/user/user.service';

/**
 * Block for creating a donation
 *
 * @export
 * @class DonationBlockComponent
 * @implements {OnInit}
 * @implements {OnChanges}
 */
@Component({
  selector: 'app-donation-block',
  templateUrl: './donation-block.component.html',
  styleUrls: ['./donation-block.component.scss'],
})
export class DonationBlockComponent implements OnInit, OnChanges, OnDestroy {
  /**
   * SF donation gotten from WP
   *
   * @type {WPCampaign}
   * @memberof DonationBlockComponent
   */
  @Input() donation: WPCampaign;

  /**
   * Settings that will only be applied on initialisation of the component
   *
   * @type {Partial<DonationSettings>}
   * @memberof DonationBlockComponent
   */
  @Input() initialSettings: Partial<DonationSettings> = {};

  /**
   * there are two types of donation: change, extra
   */
  @Input() donationType: DonationType;

  /**
   * emits when the donationType is set to 'change or 'extra'
   */
  @Output() donationTypeEmitter = new EventEmitter<DonationType>();

  /**
   * Campaigns can be childrelated, this influences te text
   */
  @Input() childRelated = false;

  @Input() hasAlreadyDonated = false;

  /**
   * if there is already one donation found for this campaign then there this 'existingDonation'
   */
  @Input() existingDonation: {
    frequency: DonationFrequency;
    amount: number;
    date: string;
    id: string;
    multiple: boolean;
  };

  @Input() sponsorChild: string;

  @Input() campaignName: string;

  /**
   * Select
   *
   * @private
   * @memberof DonationBlockComponent
   */
  private otherButtonValue = 'select';

  /**
   * Array of buttons shown with the amounts
   *
   * @type {(Array<{
   *     value: number | string;
   *     buttonText: string;
   *     isSelected: boolean;
   *   }>)}
   * @memberof DonationBlockComponent
   */
  buttons: Array<{
    value: number | string;
    buttonText: string;
    isSelected: boolean;
  }>;

  /**
   * The validness and value of the iban
   *
   * @type {{ value: string; valid: boolean }}
   * @memberof DonationBlockComponent
   */
  iban: { value: string; valid: boolean } = {
    valid: true,
    value: '',
  };

  /**
   * The holderName for the iban
   *
   * @type {string}
   * @memberof DonationBlockComponent
   */
  holderName: string;

  /**
   * The paymentprofile registered for the user
   *
   * @type {PaymentProfile}
   * @memberof DonationBlockComponent
   */
  paymentProfile: PaymentDetails;

  /**
   * The currently selected paymentType
   *
   * @type {('iDeal' | 'Direct Debit')}
   * @memberof DonationBlockComponent
   */
  type: 'iDeal' | 'Direct Debit';

  /**
   * Keeps track of the state of the selected custom amount
   *
   * @type {{ enabled: boolean; value?: number; valid?: boolean }}
   * @memberof DonationBlockComponent
   */
  customAmount: { enabled: boolean; value?: number; valid?: boolean } = {
    enabled: false,
  };

  /**
   * The currently selected frequency
   *
   * @type {('One-time' | 'Yearly')}
   * @memberof DonationBlockComponent
   */
  frequency: DonationFrequency = 'One-time';

  /**
   * The currently selected bank
   *
   * @type {BankId}
   * @memberof DonationBlockComponent
   */
  selectedBank: BankId;

  /**
   * All the banks used for the iDeal selector
   *
   * @type {Array<{ id: BankId; name: string }>}
   * @memberof DonationBlockComponent
   */
  banks: Array<{ id: BankId; name: string }> = [
    { id: 'ABNANL2A', name: 'ABN AMRO' },
    { id: 'ASNBNL21', name: 'ASN BANK' },
    { id: 'BUNQNL2A', name: 'BUNQ' },
    { id: 'INGBNL2A', name: 'ING' },
    { id: 'KNABNL2H', name: 'KNAB' },
    { id: 'RABONL2U', name: 'RABOBANK' },
    { id: 'RBRBNL21', name: 'REGIOBANK' },
    { id: 'SNSBNL2A', name: 'SNS BANK' },
    { id: 'TRIONL2U', name: 'TRIODOS BANK' },
    { id: 'FVLBNL22', name: 'VAN LANSCHOT' },
  ];

  /**
   * Internal state
   *
   * @private
   * @memberof DonationBlockComponent
   */
  private donationStatus$ = new BehaviorSubject<Partial<DonationEvent>>({
    frequency: 'One-time',
    isTouched: false,
  });

  /**
   * All the changes for the state
   *
   * @memberof DonationBlockComponent
   */
  @Output() donationChanged = this.donationStatus$.asObservable();

  formatDate = DateService.formatDate;

  accountnumberEdit: boolean;

  /**
   * Bank details of the user
   * 🥙
   */
  donerDetails: { name: string; iban: string } = { name: '', iban: '' };

  paymentProfileList: PaymentDetails[];

  /**
   * Array with all the descriptions to unsub from whe the page leaves
   *
   * @private
   * @type {Subscription[]}
   * @memberof DonationBlockComponent
   */
  private subscriptions: Subscription[] = [];

  /**
   * Creates an instance of DonationBlockComponent.
   *
   * @param {TranslateService} translationService
   * @param {UserService} userService
   * @memberof DonationBlockComponent
   */
  constructor(
    private translationService: TranslateService,
    private userService: UserService
  ) {}

  /**
   * Start the initialisation of the block
   *
   * @memberof DonationBlockComponent
   */
  ngOnInit() {
    this.init();
  }

  /**
   * Initialize the data for the block
   *
   * @private
   * @memberof DonationBlockComponent
   */
  private init() {
    if (!this.donationType) {
      this.setupButtons();
    }
    const sub$ = this.userService.getPaymentProfile$().subscribe((profile) => {
      if (!profile || !profile.profiles) {
        return;
      }

      if (!this.iban.value) {
        this.paymentProfile = {
          ...[...profile.profiles.filter((e) => e.isPrimary)].pop(),
        };
        if (!this.paymentProfile) {
          [this.paymentProfile] = profile.profiles;
        }
        this.paymentProfileList = profile.profiles;
        if (this.paymentProfile) {
          this.iban.value = this.paymentProfile.soco__IBAN__c;
          this.holderName = this.paymentProfile.soco__Holder_Name__c;
          this.donerDetails.iban = this.paymentProfile.soco__IBAN__c;
          this.donerDetails.name = this.paymentProfile.soco__Holder_Name__c;
        }
        this.checkIban();
      }
    });

    const usedKeys = [
      'donation_title_new_donation',
      'donation_description_new_donation',
      'donation_title_choice_donation',
      'donation_description_choice_donation',
      'donation_title_extra_donation',
      'donation_description_extra_donation',
      'donation_title_change_donation',
      'donation_description_change_donation',
    ];

    Object.keys(this.donation.acf).forEach((key) => {
      const index = usedKeys.indexOf(key);

      if (index >= 0) {
        this.donation.acf[key] = this.textReplace(this.donation.acf[key]);
      }
    });

    this.subscriptions.push(sub$);

    this.setupSettings();

    this.emitEvent(
      {
        destination: this.donation.acf.destination_id,
        campaign: this.donation.acf.campaign_id,
        childRelated: this.donation.acf.child_related,
      },
      false
    );
  }

  textReplace(text: string) {
    return text
      .replace('[sponsorChild]', this.sponsorChild ? this.sponsorChild : '')
      .replace(
        '[amount]',
        this.existingDonation ? `${this.existingDonation.amount}` : ''
      )
      .replace(
        '[date]',
        this.existingDonation ? this.formatDate(this.existingDonation.date) : ''
      );
  }

  /**
   * Update the donation
   *
   * @param {SimpleChanges} changes
   * @memberof DonationBlockComponent
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.donation &&
      changes.donation.currentValue &&
      !changes.donation.isFirstChange()
    ) {
      this.setupSettings();
      this.emitEvent(
        {
          destination: changes.donation.currentValue.acf.destination_id,
          campaign: changes.donation.currentValue.acf.campaign_id,
          childRelated: changes.donation.currentValue.acf.child_related,
        },
        false
      );

      this.setupButtons();
    }
  }

  setDonationType(type: DonationType) {
    this.donationType = type;
    this.donationTypeEmitter.emit(type);

    let frequency = this.existingDonation
      ? this.existingDonation.frequency
      : 'Yearly';

    if (type === 'extra') {
      frequency = 'One-time';
    }
    this.setFrequency(frequency);

    this.setupButtons();
  }

  /**
   * Close al the subscriptions
   *
   * @memberof DonationBlockComponent
   */
  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  /**
   * Initialises state for given options
   *
   * @private
   * @returns
   * @memberof DonationBlockComponent
   */
  private setupSettings() {
    if (!this.initialSettings) {
      return;
    }

    const { amount, paymentType, bank, frequency } = this.initialSettings;

    if (amount) {
      this.buttonClicked(amount, true);
      if (this.customAmount.enabled) {
        this.customAmount.value = amount;
        this.setCustomValue();
      }
    }

    if (paymentType) {
      this.switchRadio(paymentType);

      if (bank) {
        this.selectBank(bank);
      }
    }

    if (frequency) {
      this.setFrequency(this.donation.acf.default_recurrency);
    }
  }

  /**
   * Creates the buttons from the WP Donation
   *
   * @private
   * @memberof DonationBlockComponent
   */
  private setupButtons() {
    this.buttons = [];
    const { proposed_1, proposed_2, proposed_3 } = this.donation.acf;
    if (proposed_1 && proposed_2 && proposed_3) {
      // all three amounts are added in cms
      [proposed_1, proposed_2, proposed_3].forEach((item) => {
        if (Number.isNaN(+item)) {
          return;
        }
        let buttonText = '€ ';

        if (Number.isInteger(+item)) {
          buttonText += `${+item},-`;
        } else {
          buttonText += `${(+item).toFixed(2)}`;
        }

        this.buttons.push({
          value: +item,
          buttonText,
          isSelected: false,
        });
      });

      this.buttons.push({
        value: this.otherButtonValue,
        buttonText: this.translationService.instant('DONATION.other'),
        isSelected: false,
      });
    } else {
      // less then three amounts are added in cms, then we only want to show 1 custom input field.
      this.buttons.push({
        value: this.otherButtonValue,
        buttonText: this.translationService.instant('DONATION.customAmount'),
        isSelected: false,
      });
      this.customAmount.enabled = true;
      this.customAmount.valid = true;
    }

    if (this.donationType === 'change') {
      this.checkExistingValues();
    }
  }

  /**
   * Set a custom amount and update state
   *
   * @memberof DonationBlockComponent
   */
  setCustomValue() {
    if (
      this.customAmount.enabled &&
      this.customAmount.value &&
      !Number.isNaN(this.customAmount.value) &&
      this.customAmount.value >= 1 &&
      this.customAmount.value >= this.donation.acf.minimal_amount &&
      this.customAmount.value <= this.donation.acf.maximum_amount
    ) {
      this.emitEvent({ amount: this.customAmount.value });
      this.customAmount.valid = true;
    } else if (this.customAmount.enabled) {
      this.emitEvent({ amount: undefined });
      this.customAmount.valid = false;
    }
  }

  /**
   * check existingValues if hasAlreadyDonated is true and the user wants to change the value
   */
  checkExistingValues() {
    // check if customValue is already in a proposed button.
    const buttonIndex = this.buttons.findIndex(
      (button) => button.value === this.existingDonation.amount
    );
    if (buttonIndex !== -1) {
      // if proposed button is found: select the proposed button
      this.buttons[buttonIndex].isSelected = true;
    } else {
      // else the custom amount button should be selected and the amount should be filled in
      const selectButtonIndex = this.buttons.findIndex(
        (button) => button.value === 'select'
      );
      this.buttons[selectButtonIndex].isSelected = true;
      this.customAmount.enabled = true;
      this.customAmount.valid = true;
      this.customAmount.value = this.existingDonation.amount;
      this.setCustomValue();
    }
  }

  /**
   * Select an amount and update state
   *
   * @param {(number | string)} value
   * @param {boolean} [bySettings=false]
   * @memberof DonationBlockComponent
   */
  buttonClicked(value: number | string, bySettings: boolean = false) {
    let hasChanged = false;
    this.buttons = [
      ...this.buttons.map((rawButton) => {
        const button = { ...rawButton };
        if (button.value === value && !button.isSelected) {
          hasChanged = true;
          button.isSelected = true;
        } else if (button.value !== value) {
          button.isSelected = false;
        }
        return button;
      }),
    ];
    if (hasChanged && !Number.isNaN(+value)) {
      this.customAmount.enabled = false;
      this.emitEvent({
        amount: +value,
        valid: true,
        destination: this.donation.acf.destination_id,
      });
    } else if (hasChanged) {
      this.customAmount.enabled = true;
      let newAmount: number;
      if (this.donation.acf.proposed_2) {
        newAmount = +this.donation.acf.proposed_2;
      } else {
        newAmount = +this.donation.acf.proposed_1;
      }

      if (this.customAmount.value) {
        newAmount = this.customAmount.value;
      } else {
        this.customAmount.value = newAmount;
      }

      this.setCustomValue();
    } else if (!hasChanged && bySettings) {
      this.buttonClicked(this.otherButtonValue);
    }
  }

  /**
   * Change between payment methods and update state
   *
   * @param {('iDeal' | 'Direct Debit')} value
   * @memberof DonationBlockComponent
   */
  switchRadio(value: 'iDeal' | 'Direct Debit') {
    this.type = value;
    if (this.type === 'Direct Debit' && this.selectedBank) {
      this.selectedBank = undefined;
    }

    if (this.type === 'Direct Debit' && this.iban.value) {
      this.checkIban();
    }

    if (this.type === 'iDeal') {
      this.iban.valid = true;
      this.accountnumberEdit = false;
    }

    this.emitEvent({ paymentType: this.type });
  }

  /**
   * Select a bank for iDeal and update state
   *
   * @param {BankId} event
   * @memberof DonationBlockComponent
   */
  selectBank(event: BankId) {
    this.selectedBank = event;
    this.emitEvent({
      bank: this.selectedBank,
    });
  }

  /**
   * Change the frequency of the donation and update state
   *
   * @param {('One-time' | 'Yearly')} type
   * @memberof DonationBlockComponent
   */
  setFrequency(type: DonationFrequency) {
    this.frequency = type;
    if (type !== 'One-time' && this.type !== 'Direct Debit') {
      this.switchRadio('Direct Debit');
    }
    this.emitEvent({ frequency: this.frequency });
  }

  /**
   * Check if iban is valid if it is valid then update state
   *
   * @memberof DonationBlockComponent
   */
  checkIban() {
    this.iban.valid = HelperService.checkIban(this.iban.value);
    if (this.iban.valid && this.type === 'Direct Debit') {
      this.emitEvent({ iban: this.iban.value });
      const index = this.paymentProfileList.findIndex(
        (i) => i.soco__IBAN__c === this.iban.value
      );

      if (index >= 0) {
        this.holderName = this.paymentProfileList[index].soco__Holder_Name__c;
      }
      this.updateHolder();
    } else if (this.donationStatus$.value && this.donationStatus$.value.iban) {
      this.emitEvent({ iban: undefined });
    }
  }

  /**
   * Change the holderName
   *
   * @memberof DonationBlockComponent
   */
  updateHolder() {
    this.emitEvent({ ibanHolder: this.holderName });
  }

  /**
   * Function that will update the current state of the object
   *
   * @private
   * @param {Partial<DonationEvent>} object
   * @param {boolean} [updateTouched=true]
   * @memberof DonationBlockComponent
   */
  private emitEvent(
    object: Partial<DonationEvent>,
    updateTouched: boolean = true
  ) {
    const currentValue = { ...this.donationStatus$.value };
    const newValue = { ...currentValue, ...object };
    newValue.valid = !!(
      newValue.amount > 0 &&
      newValue.destination &&
      ((newValue.paymentType === 'Direct Debit' &&
        newValue.ibanHolder &&
        newValue.iban &&
        this.iban.valid) ||
        (newValue.paymentType === 'iDeal' && newValue.bank))
    );

    if (
      this.paymentProfile &&
      newValue.paymentType === 'Direct Debit' &&
      newValue.iban &&
      this.iban.valid &&
      this.paymentProfile.soco__IBAN__c &&
      newValue.iban.toUpperCase().replace(' ', '') ===
        this.paymentProfile.soco__IBAN__c.toUpperCase().replace(' ', '')
    ) {
      newValue.paymentProfileId = this.paymentProfile.Id;
    } else if (newValue.paymentProfileId) {
      delete newValue.paymentProfileId;
    }

    if (updateTouched) {
      newValue.isTouched = true;
    }

    this.donationStatus$.next(newValue);
  }

  /**
   * Reset the form to the initial state
   *
   * @memberof DonationBlockComponent
   */
  resetForm() {
    this.type = undefined;
    this.customAmount = {
      enabled: false,
    };
    this.frequency = 'One-time';
    this.selectedBank = undefined;
    if (this.donationType === 'change' || this.donationType === 'extra') {
      this.donationType = null;
    }
    this.init();
  }

  editAccount() {
    this.holderName = this.donerDetails.name;
    this.iban.value = this.donerDetails.iban;
    this.accountnumberEdit = true;
    this.emitEvent({
      ibanHolder: this.holderName,
      iban: this.iban.value,
    });
  }

  stopEdit() {
    this.donerDetails.name = this.holderName;
    this.donerDetails.iban = this.iban.value;
    this.accountnumberEdit = false;
    this.emitEvent({
      ibanHolder: this.holderName,
      iban: this.iban.value,
    });
  }

  cancelEdit() {
    this.holderName = this.donerDetails.name;
    this.iban.value = this.donerDetails.iban;
    this.iban.valid = HelperService.checkIban(this.donerDetails.iban);
    this.emitEvent({
      ibanHolder: this.donerDetails.name,
      iban: this.donerDetails.iban,
    });
    this.accountnumberEdit = false;
  }
}
