<section class="accordions">
  <article class="accordion" *ngFor="let item of this.questions; let i = index" [ngClass]="{'is-active': item.isOpen}">
    <div class="accordion-header toggle" (click)="toggleQuestion(i)">
      <h4 class="u-display-flex space-between center u-width-100 u-padding-05">
        <span class="u-block">{{ item.question }}</span>
        <i class="fa fa-angle-down icon-large" *ngIf="!item.isOpen"></i>
        <i class="fa fa-angle-up icon-large" *ngIf="item.isOpen"></i>
      </h4>
    </div>
    <div class="accordion-body">
      <div class="accordion-content" [innerHTML]="item.answer">
      </div>
    </div>
  </article>
</section>
