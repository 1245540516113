<div
  *ngIf="commitment"
  class="{{ commitment.BeneficiaryResponse.Gender }} child-card"
>
  <div *ngIf="!detail" class="overview-card bg-white">
    <div class="image">
      <div
        class="cover"
        (click)="goToChild()"
        [ngStyle]="{
          'background-image':
            'url(' + commitment.BeneficiaryResponse.smallImageUrl + ')'
        }"
      >
        <img [src]="commitment.BeneficiaryResponse.smallImageUrl" />
        <img
          *ngIf="commitment.birthdayNearFuture"
          class="birthday-img"
          src="assets/birthday2x.png"
          alt=""
        />
        <div class="detail-button txt-center">
          <a (click)="goToChild()"
            ><i class="far fa-angle-right icon xl icon-white"></i
          ></a>
        </div>
      </div>
      <div
        *ngIf="commitment.sponsorType !== 'full'"
        class="corner-label-sponsors tooltip-label"
      >
        <i
          *ngIf="commitment.sponsorType === 'financial'"
          class="corner-icon fas fa-coins icon-primary sponsor"
          tooltip="{{ 'CHILDREN.sponsorship.financial' | translate }}"
        ></i>
        <i
          *ngIf="commitment.sponsorType === 'write'"
          class="fas fa-pencil corner-icon icon-primary sponsor"
          tooltip="{{ 'CHILDREN.sponsorship.write' | translate }}"
          translate
        ></i>
      </div>
    </div>
    <div class="info txt-center">
      <h3 class="child-name notranslate">
        {{ commitment.BeneficiaryResponse.PreferredName }}
      </h3>
      <ng-container *ngIf="commitment.birthdayNearFuture; else noBirthdayInfo">
        <p class="notranslate">
          {{
            'CHILDREN.daysToBirthDay'
              | pluralizerTranslate
                : {
                    amount: commitment.BeneficiaryResponse.daysTillBirthday,
                    name: commitment.BeneficiaryResponse.PreferredName
                  }
          }}
        </p>
      </ng-container>
      <ng-template #noBirthdayInfo>
        <p>
          <span [ngSwitch]="commitment.isOnlyACorrespondent">
            <div *ngSwitchCase="true" class="notranslate">
              {{
                !commitment.BeneficiaryResponse.nameTooLong
                  ? ('CHILDREN.writeDate'
                    | translate
                      : { name: commitment.BeneficiaryResponse.PreferredName })
                  : ('CHILDREN.writeRef'
                    | genderTranslate
                      : commitment.BeneficiaryResponse.Gender === 'Man')
              }}
              {{
                moment(commitment.Commitment.StartDate).format('DD MMMM YYYY')
              }}
            </div>

            <div *ngSwitchCase="false">
              {{
                !commitment.BeneficiaryResponse.nameTooLong
                  ? ('CHILDREN.sponsorDate'
                    | translate
                      : { name: commitment.BeneficiaryResponse.PreferredName })
                  : ('CHILDREN.sponsorRef'
                    | genderTranslate
                      : commitment.BeneficiaryResponse.Gender === 'Man')
              }}
              {{
                moment(commitment.Commitment.StartDate).format('DD MMMM YYYY')
              }}
            </div>
          </span>
        </p>
      </ng-template>
    </div>
    <ng-template [ngTemplateOutlet]="cardButtons"></ng-template>
    <div class="child-card-footer">
      <div class="u-width-50 txt-center u-padding-1">
        <h5>{{ 'CHILDREN.age' | translate | titlecase }}</h5>
        <p>
          {{
            'CHILDREN.currentAge'
              | translate: { year: commitment.BeneficiaryResponse.ageInYears }
          }}
        </p>
      </div>
      <div class="u-width-50 txt-center u-padding-1">
        <h5>{{ 'CHILDREN.country' | translate }}</h5>
        <p>{{ commitment.BeneficiaryResponse.Country }}</p>
      </div>
    </div>
  </div>

  <div *ngIf="detail" class="detail-card bg-white container">
    <div
      class="image is-half-print"
      (click)="clickImage(commitment.BeneficiaryResponse.largeImageUrl, $event)"
      [ngStyle]="{
        'background-image':
          'url(' + commitment.BeneficiaryResponse.largeImageUrl + ')'
      }"
    >
      <img [src]="commitment.BeneficiaryResponse.largeImageUrl" />
      <img
        *ngIf="commitment.birthdayNearFuture"
        class="birthday-img"
        src="assets/birthday2x.png"
        alt=""
      />
      <app-tooltip
        class="child-card-tooltip notranslate"
        location="sponsorchildpicinfo"
        [name]="commitment.BeneficiaryResponse.PreferredName"
      ></app-tooltip>
    </div>
    <div class="content is-half-print">
      <app-tooltip
        class="child-card-tooltip notranslate"
        location="sponsorchildinfo"
        [name]="commitment.BeneficiaryResponse.PreferredName"
      ></app-tooltip>
      <div class="child-card-content">
        <h3 class="child-name notranslate">
          {{ commitment.BeneficiaryResponse.PreferredName }}
        </h3>
        <ul class="info-list is-marginless">
          <li class="info-list-item">
            <h5 class="width-category">{{ 'MISC.isA' | translate }}</h5>
            <p>
              {{
                (commitment.BeneficiaryResponse.Gender === 'Man'
                  ? 'MISC.boy'
                  : 'MISC.girl'
                ) | translate
              }}
            </p>
          </li>
          <li class="info-list-item">
            <h5 class="width-category">{{ 'CHILDREN.age' | translate }}</h5>
            <p>
              {{
                'CHILDREN.currentAge'
                  | translate
                    : { year: commitment.BeneficiaryResponse.ageInYears }
              }}
            </p>
          </li>
          <li class="info-list-item">
            <h5 class="width-category">{{ 'MISC.bornOn' | translate }}</h5>
            <p>
              {{
                moment(commitment.BeneficiaryResponse.BirthDate).format(
                  'DD MMMM YYYY'
                )
              }}
            </p>
          </li>
          <li class="info-list-item">
            <h5 class="width-category">{{ 'MISC.livesIn' | translate }}</h5>
            <p>{{ commitment.BeneficiaryResponse.Country }}</p>
          </li>
        </ul>
        <div class="section">
          <app-button
            (buttonPressed)="
              previewBioNewTab(
                commitment.BeneficiaryResponse.Beneficiary_GlobalID
              )
            "
            class="bio"
            angulartics2On="click"
            angularticsAction="download"
            angularticsCategory="commitmentBio"
          >
            <div class="btn-with-img-container">
              <i class="fa fa-download"></i>
              <div>{{ 'BUTTON.downloadBio' | translate }}</div>
            </div>
          </app-button>
        </div>
        <p
          class="sponsor-since notranslate"
          *ngIf="commitment.isOnlyACorrespondent"
        >
          {{
            'CHILDREN.writeDate'
              | translate
                : { name: commitment.BeneficiaryResponse.PreferredName }
          }}
          {{ moment(commitment.Commitment.StartDate).format('DD MMMM YYYY') }}
        </p>
        <p
          class="sponsor-since notranslate"
          *ngIf="!commitment.isOnlyACorrespondent"
        >
          {{
            'CHILDREN.sponsorDate'
              | translate
                : { name: commitment.BeneficiaryResponse.PreferredName }
          }}
          {{ moment(commitment.Commitment.StartDate).format('DD MMMM YYYY') }}
        </p>
      </div>
      <div class="child-card-footer">
        <h3
          class="txt-center notranslate"
          *ngIf="commitment.birthdayNearFuture"
        >
          {{
            'CHILDREN.daysToBirthDay'
              | pluralizerTranslate
                : {
                    amount: commitment.BeneficiaryResponse.daysTillBirthday,
                    name: commitment.BeneficiaryResponse.PreferredName
                  }
          }}
        </h3>
        <ng-template [ngTemplateOutlet]="cardButtons"></ng-template>
      </div>
    </div>
  </div>
</div>

<ng-template #cardButtons>
  <ng-container *ngIf="!commitment.birthdayNearFuture; else birthdayButtons">
    <div class="child-card-button-container is-hidden-print" [ngClass]="{'u-padding-bottom-0': detail}">
      <div class="button-container" [ngClass]="{'u-row': detail}">
        <app-button
          *ngIf="commitment.isCorrespondent"
          (buttonPressed)="
            writeLetter('letters', commitment.birthdayNearFuture)
          "
          gender="{{ commitment.BeneficiaryResponse.Gender }}"
        >
          <div class="btn-with-img-container">
            <img class="image-width" src="assets/icons/write.svg" />
            <p>{{ 'BUTTON.write' | translate }}</p>
          </div>
        </app-button>
        <app-button
          (buttonPressed)="giveGift(false)"
          gender="{{ commitment.BeneficiaryResponse.Gender }}"
        >
          <div class="btn-with-img-container">
            <i class="far fa-heart icon-button"></i>
            <p>{{ 'BUTTON.give' | translate }}</p>
          </div>
        </app-button>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #birthdayButtons>
  <ng-container *ngIf="!detail; else birthdayDetailButtons">
    <div class="child-card-button-container is-hidden-print">
      <div class="write-button-holder u-txt-align-center" *ngIf="commitment.isCorrespondent">
        <div *ngIf="commitment.writtenBirthdayCard">
          {{ 'BUTTON.wrote_birthday' | translate }}
        </div>
        <app-button
          (buttonPressed)="
            writeLetter(
              !commitment.writtenBirthdayCard ? 'cards' : 'letters',
              !commitment.writtenBirthdayCard
            )
          "
          gender="{{ commitment.BeneficiaryResponse.Gender }}"
        >
          <div class="btn-with-img-container">
            <img class="image-width" src="assets/icons/write.svg" />
            <p>
              {{
                (commitment.writtenBirthdayCard
                  ? 'BUTTON.write'
                  : 'BUTTON.write_card'
                ) | translate
              }}
            </p>
          </div>
        </app-button>
      </div>
      <div
        class="give-button-holder u-txt-align-center"
        [ngClass]="{
          'padding-above':
            commitment.isCorrespondent && commitment.givenBirthdayDonation
        }"
      >
        <div *ngIf="commitment.givenBirthdayDonation">
          {{ 'BUTTON.gave_birthday' | translate }}
        </div>
        <app-button
          *ngIf="commitment.givenBirthdayDonation"
          (buttonPressed)="giveGift(false)"
          gender="{{ commitment.BeneficiaryResponse.Gender }}"
        >
          <div class="btn-with-img-container">
            <i class="far fa-heart icon-button"></i>
            <p>{{ 'BUTTON.give' | translate }}</p>
          </div>
        </app-button>
        <app-button
          *ngIf="!commitment.givenBirthdayDonation"
          (buttonPressed)="giveGift(true)"
          gender="{{ commitment.BeneficiaryResponse.Gender }}"
        >
          <div class="btn-with-img-container">
            <img src="assets/icons/birthday.svg" />
            <p>{{ 'BUTTON.give_birthday' | translate }}</p>
          </div>
        </app-button>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #birthdayDetailButtons>
  <div class="child-card-button-container u-padding-bottom-0 is-hidden-print">
    <div
      class="info-row u-col u-txt-align-center"
      *ngIf="
        (commitment.isCorrespondent && commitment.writtenBirthdayCard) ||
        commitment.givenBirthdayDonation
      "
    >
      <div *ngIf="commitment.isCorrespondent && commitment.writtenBirthdayCard" class="info-text">
        {{ 'BUTTON.wrote_birthday' | translate }}
      </div>
      <div *ngIf="commitment.givenBirthdayDonation" class="info-text">
        {{ 'BUTTON.gave_birthday' | translate }}
      </div>
    </div>
    <div class="button-container u-col">
      <app-button
        *ngIf="commitment.isCorrespondent"
        (buttonPressed)="
          writeLetter(
            !commitment.writtenBirthdayCard ? 'cards' : 'letters',
            !commitment.writtenBirthdayCard
          )
        "
        gender="{{ commitment.BeneficiaryResponse.Gender }}"
      >
        <div class="btn-with-img-container">
          <img class="image-width" src="assets/icons/write.svg" />
          <p>
            {{
              (commitment.writtenBirthdayCard
                ? 'BUTTON.write'
                : 'BUTTON.write_card'
              ) | translate
            }}
          </p>
        </div>
      </app-button>
      <app-button
        *ngIf="commitment.givenBirthdayDonation"
        (buttonPressed)="giveGift(false)"
        gender="{{ commitment.BeneficiaryResponse.Gender }}"
      >
        <div class="btn-with-img-container">
          <i class="far fa-heart icon-button"></i>
          <p>{{ 'BUTTON.give' | translate }}</p>
        </div>
      </app-button>
      <app-button
        *ngIf="!commitment.givenBirthdayDonation"
        (buttonPressed)="giveGift(true)"
        gender="{{ commitment.BeneficiaryResponse.Gender }}"
      >
        <div class="btn-with-img-container">
          <img src="assets/icons/birthday.svg" />
          <p>{{ 'BUTTON.give_birthday' | translate }}</p>
        </div>
      </app-button>
    </div>
  </div>
</ng-template>

<!-- download documents -->
<ng-template #previewpopup>
  <div class="alert-container">
    <div class="level">
      <h2 class="level-left">{{ 'PDF.MODAL.preview' | translate }}</h2>
      <i
        class="level-right fas fa-times icon large txt-primary"
        (click)="closeModal()"
      ></i>
    </div>
    <div *ngIf="!mailDownloadSrc">
      <app-loader size="small" color="blue" class="col-12"></app-loader>
    </div>
    <div class="pdfViewer" *ngIf="mailDownloadSrc">
      <pdf-viewer
        [src]="mailDownloadSrc"
        [render-text]="true"
        style="display: block"
      ></pdf-viewer>
    </div>
    <div class="btn-container between" *ngIf="mailDownloadSrc">
      <app-button (buttonPressed)="downloadBio()">
        <div class="btn-with-img-container">
          <i class="far fa-download icon-button"></i>
          <p>
            {{ 'PDF.MODAL.download' | translate }}
          </p>
        </div>
      </app-button>
      <app-button (buttonPressed)="printBio()">
        <div class="btn-with-img-container">
          <i class="far fa-print icon-button"></i>
          <p>
            {{ 'PDF.MODAL.print' | translate }}
          </p>
        </div>
      </app-button>
    </div>
  </div>
</ng-template>
