import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { DonationBlockComponent } from '../../components/donation-block/donation-block.component';
import { Commitment } from '../../interfaces/commitment.interface';
import {
  DonationEvent,
  DonationFrequency,
  DonationSettings,
  DonationType,
} from '../../interfaces/donation.interface';
import { RecurringPayment } from '../../interfaces/payment.interface';
import { WPCampaign } from '../../interfaces/WPCampaign.interface';
import { CommitmentService } from '../../services/commitment/commitment.service';
import { DateService } from '../../services/date.service';
import { Donation, DonationService } from '../../services/donation.service';
import { PaymentService } from '../../services/payment/payment.service';
import { PopupService } from '../../services/popup.service';
import { WordpressService } from '../../services/wordpress/wordpress.service';

@Component({
  selector: 'app-donate',
  templateUrl: './donate.page.html',
  styleUrls: ['./donate.page.scss'],
})
export class DonatePage implements OnInit, OnDestroy {
  loading: boolean = true;

  isSending: boolean = false;

  donation: WPCampaign;

  commitment: Commitment;

  defaultSettings: Partial<DonationSettings> = {
    frequency: 'Yearly',
    paymentType: 'Direct Debit',
  };

  @ViewChild('alertpopup') alertpopup: TemplateRef<any>;

  @ViewChild('donationBlock') donationBlock: DonationBlockComponent;

  popupType: 'not-touched' | 'not-done';

  donationStatus: DonationEvent;

  donationType: DonationType;

  existingDonation: {
    frequency: DonationFrequency;
    amount: number;
    date: string;
    id: string;
    multiple: boolean;
  };

  sponsorChild: string;

  campaignName: string;

  hasAlreadyDonated = false;

  formatDate = DateService.formatDate;

  private commitmentId: string;

  donationInitial: Partial<WPCampaign>;

  private subscriptions: Subscription[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private donationService: DonationService,
    private popupService: PopupService,
    private wordpressService: WordpressService,
    private commitmentService: CommitmentService,
    private paymentService: PaymentService
  ) {}

  ngOnInit(): void {
    if (!environment.features.canDonate) {
      this.router.navigate(['/']);
      return;
    }

    const route$ = this.activatedRoute.queryParams
      .pipe(
        switchMap((params: { donation: string; commitment: string }) => {
          const {
            donation,
            commitment,
          }: { donation: string; commitment: string } = params;
          if (!donation) {
            return of([undefined, undefined]);
          }
          const obs: Array<Observable<any>> = [];

          obs.push(this.wordpressService.getCampaignForSlug(donation));

          if (!commitment) {
            obs.push(of(false));
          } else {
            obs.push(
              this.commitmentService
                .getCommitmentById(commitment)
                .pipe(map((res) => res.commitment))
            );
          }

          obs.push(this.paymentService.getRecurringPayments$());

          return combineLatest(obs);
        })
      )
      .subscribe(
        ([campaign, commitment, payments]: [
          WPCampaign,
          Commitment,
          RecurringPayment[]
        ]) => {
          if (!campaign && !commitment) {
            this.router.navigate(['/']);
            return;
          }
          this.donation = { ...campaign };

          if (!commitment) {
            this.sponsorChild = '';
          } else {
            this.sponsorChild = commitment.BeneficiaryResponse.PreferredName;
          }

          if (payments) {
            let isFound = false;

            payments.forEach((payment) => {
              let check =
                payment.soco__Destination_lookup__c ===
                this.donation.acf.destination_id;
              if (commitment) {
                check =
                  payment.soco__Destination_lookup__c ===
                    this.donation.acf.destination_id &&
                  payment.Beneficiary_GlobalID__c ===
                    commitment.BeneficiaryResponse.Beneficiary_GlobalID;
              }

              if (check) {
                let amount = payment.soco__Amount__c;
                let multipleFound = false;

                if (isFound) {
                  amount += this.existingDonation.amount;
                  multipleFound = true;
                }

                this.existingDonation = {
                  frequency: payment.soco__Frequency__c,
                  amount,
                  date: payment.soco__Next_Collection_Date__c,
                  id: payment.Id,
                  multiple: multipleFound,
                };
                isFound = true;
              }
            });
            this.hasAlreadyDonated = isFound;
          }

          this.campaignName = this.donation.title.rendered;

          if (commitment && this.donation.acf.child_related) {
            this.commitmentId = commitment.Commitment.Commitment_GPID;
          }

          const usedKeys = [
            'donation_title_global',
            'donation_description_global',
          ];

          Object.keys(this.donation.acf).forEach((key) => {
            const index = usedKeys.indexOf(key);

            if (index >= 0) {
              this.donation.acf[key] = this.textReplace(this.donation.acf[key]);
            }
          });
          this.donationInitial = this.donation;
          this.loading = false;
        }
      );

    this.subscriptions.push(route$);
  }

  setDonationType(type: DonationType) {
    this.donationType = type;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.subscriptions = [];
  }

  updateDonation(event: DonationEvent) {
    this.donationStatus = { ...event };
  }

  textReplace(text: string) {
    return text
      .replace('[sponsorChild]', this.sponsorChild ? this.sponsorChild : '')
      .replace(
        '[amount]',
        this.existingDonation ? `${this.existingDonation.amount}` : ''
      )
      .replace(
        '[date]',
        this.existingDonation ? this.formatDate(this.existingDonation.date) : ''
      );
  }

  closeModal() {
    this.popupType = undefined;
    this.popupService.closePopup();
  }

  cancelDonation() {
    this.popupType = undefined;
    this.popupService.closePopup();
  }

  submitDonation() {
    if (this.isSending) {
      return;
    }

    if (
      this.donationStatus &&
      !this.donationStatus.valid &&
      this.donationStatus.isTouched
    ) {
      this.popupType = 'not-done';
      this.popupService.createPopup(this.alertpopup);
      return;
    }

    if (
      !this.donationStatus ||
      !this.donationStatus.isTouched ||
      !this.donationStatus.valid
    ) {
      this.popupType = 'not-touched';
      this.popupService.createPopup(this.alertpopup);
      return;
    }

    // #region Create donation to send
    const donation = { ...this.donationStatus };

    let errorURL = `${environment.apiUrl}redirect?url=/donate/failed`; // -> angular-app/donate/failed
    const typeOfSuccess =
      this.donationType === 'change' ? 'success-change' : 'success';
    let successURL = `${environment.apiUrl}redirect?url=/donate/${typeOfSuccess}`; // -> angular-app/donate/success?slug=name-of-slug or angular-app/donate/success-change?slug=name-of-slug
    if (this.donation.slug) {
      const addition = `?slug=${this.donation.slug}`;
      errorURL += addition;
      successURL += addition;
    }

    const sendDonation: Partial<Donation> = {
      id: this.donationType === 'change' ? this.existingDonation.id : null, // if 'change' then add id
      amount: donation.amount,
      destination: donation.destination,
      method: donation.paymentType,
      frequency: donation.frequency,
      campaign: donation.campaign,
      holderName: donation.ibanHolder,
      errorURL,
      successURL,
      multiple: this.existingDonation && this.existingDonation.multiple,
      wpCampaign: this.donation,
    };

    if (donation.childRelated) {
      sendDonation.commitment = this.commitmentId;
    }

    if (donation.paymentType === 'iDeal') {
      sendDonation.bank = donation.bank;
    }

    if (donation.paymentType === 'Direct Debit') {
      sendDonation.holderName = donation.ibanHolder;
      sendDonation.iban = donation.iban;
      if (donation.paymentProfileId) {
        sendDonation.paymentProfileId = donation.paymentProfileId;
      }
    }
    // #endregion

    this.isSending = true;
    const sub$ = this.donationService.sendDonation(sendDonation).subscribe(
      ({ isSuccess, redirectURL }) => {
        this.isSending = false;
        if (!isSuccess) {
          // Shouldn't happen
          window.location.href = sendDonation.errorURL;
        }

        const datalayerItem = {
          event: 'donation',
          ecommerce: {
            purchase: {
              actionField: {
                id: Date.now(),
                revenue: sendDonation.amount,
                option: sendDonation.method,
                affiliation: 'MyCompassion',
                tax: 0,
                shipping: 0,
                step: 4,
              },
              products: [
                {
                  name: this.donation.title.rendered,
                  id: this.donation.acf.destination_name,
                  price: sendDonation.amount,
                  category: this.donation.acf.product_category,
                  variant: sendDonation.frequency,
                  quantity: 1,
                },
              ],
            },
          },
        };

        // @ts-ignore
        window.dataLayer = window.dataLayer || [];

        // @ts-ignore
        window.dataLayer.push(datalayerItem);

        if (sendDonation.method === 'iDeal') {
          if (redirectURL) {
            window.location.href = redirectURL;
            return;
          }
          window.location.href = sendDonation.errorURL;
        }

        if (isSuccess && sendDonation.method === 'Direct Debit') {
          window.location.href = sendDonation.successURL;
        }
      },
      (error) => {
        if (error === 'Donation invalid') {
          // Documented here, but should be impossible to reach!
        }
        this.isSending = false;
      }
    );

    this.subscriptions.push(sub$);
  }

  resetDonation() {
    if (this.donationBlock) {
      this.donationStatus.isTouched = false;
      this.donationBlock.resetForm();
    }

    this.donationType = undefined;
  }
}
