import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AdDirective } from './ad.directive';
import { TooltipProperties } from './interfaces';
import { OverlayModule } from './overlay/overlay.module';
import { TooltipElementDirective } from './tooltip-element.directive';
import { TooltipPropertiesService } from './tooltip-properties.service';
import { TooltipTriggerDirective } from './tooltip-trigger.directive';
import { TooltipComponent } from './tooltip.component';
import { Tooltip } from './tooltip.service';

@NgModule({
  declarations: [
    TooltipComponent,
    AdDirective,
    TooltipElementDirective,
    TooltipTriggerDirective,
  ],
  imports: [CommonModule, OverlayModule],
  exports: [TooltipElementDirective, TooltipTriggerDirective],
  providers: [Tooltip],
  bootstrap: [],
  entryComponents: [TooltipComponent],
})
/**
 * Tooltipmodule pro version of IvyTip
 */
export class TooltipModule {
  static forRoot(
    initProperties: TooltipProperties
  ): ModuleWithProviders<TooltipModule> {
    return {
      ngModule: TooltipModule,
      providers: [
        {
          provide: TooltipPropertiesService,
          useValue: initProperties,
        },
      ],
    };
  }
}
