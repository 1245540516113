import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Angulartics2Module } from 'angulartics2';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxMasonryModule } from 'ngx-masonry';
import { DirectivesModule } from '../directives/directives.module';
import { PipesModule } from '../pipes/Pipes.module';
import { TooltipModule, TooltipProperties } from '../tooltip-pro';
import { BannerComponent } from './banner/banner.component';
import { ButtonComponent } from './button/button.component';
import { CampaignCardComponent } from './campaign-card/campaign-card.component';
import { CardComponent } from './card/card.component';
import { ChartComponent } from './chart/chart.component';
import { ChildCardComponent } from './child-card/child-card.component';
import { DigitalConsentComponent } from './digital-consent/digital-consent.component';
import { DonationBlockComponent } from './donation-block/donation-block.component';
import { FAQComponent } from './faq/faq.component';
import { FooterComponent } from './footer/footer.component';
import { GraphCardComponent } from './graph-card/graph-card.component';
import { HeaderComponent } from './header/header.component';
import { InboxComponent } from './inbox/inbox.component';
import { LoaderComponent } from './loader/loader.component';
import { LocationInfoComponent } from './location-info/location-info.component';
import { PaymentsOverviewComponent } from './payments-overview/payments-overview.component';
import { PopUpContentComponent } from './popup-content/popup-content.component';
import { PopUpComponent } from './popup/popup.component';
import { ProfileCardComponent } from './profile-card/profile-card.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { WidgetComponent } from './widget/widget.component';
import { MultiColumnTextWidgetComponent } from './multi-column-text-widget/multi-column-text-widget.component';

const myDefaultTooltipOptions: TooltipProperties = {
  hideDelay: 100,
  maxWidth: '20rem',
  theme: 'light',
};

/**
 * @ignore for documentation
 */
const COMPONENTS = [
  FooterComponent,
  HeaderComponent,
  BannerComponent,
  ButtonComponent,
  ChildCardComponent,
  InboxComponent,
  PaymentsOverviewComponent,
  LocationInfoComponent,
  LoaderComponent,
  TooltipComponent,
  DonationBlockComponent,
  PopUpComponent,
  PopUpContentComponent,
  DigitalConsentComponent,
  ProfileCardComponent,
  CampaignCardComponent,
  CardComponent,
  GraphCardComponent,
  ChartComponent,
  WidgetComponent,
  FAQComponent,
];
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    PipesModule,
    DirectivesModule,
    FormsModule,
    Angulartics2Module,
    PdfViewerModule,
    TooltipModule.forRoot(myDefaultTooltipOptions as TooltipProperties),
    NgxMasonryModule,
  ],
  entryComponents: [],
  declarations: [COMPONENTS, MultiColumnTextWidgetComponent],
  exports: [COMPONENTS, TranslateModule, PipesModule, NgxMasonryModule],
})
export class SharedModule {}
