<div class="donate page-container">
  <div class="grid">
    <div class="columns">
      <app-loader *ngIf="loading" color="blue" class="col-12"></app-loader>
      <div *ngIf="donation" class="col-12">
        <div class="col-12">
          <h1 *ngIf="donation.acf.donation_title_global">
            {{donation.acf.donation_title_global}}
          </h1>
          <h3
            *ngIf="donation.acf.donation_description_global"
            [innerHTML]="donation.acf.donation_description_global"
          ></h3>
        </div>
        <div
          class="columns is-centered"
          *ngIf="hasAlreadyDonated && donationType"
        >
          <div class="col-12 col-lg-12">
            <h1>{{ 'PROFILE.DONATION.header' | translate }}</h1>
            <p
              class="u-padding-top-10 u-padding-bottom-10"
              [innerHTML]="'PROFILE.DONATION.subtitle' | translate"
            ></p>
            <div class="com-link">
              <span class="icon">
                <i class="fa fa-chevron-left is-medium"></i>
              </span>
              <a class="has-text-link" (click)="resetDonation()"
                >{{ 'PROFILE.goBackToDonations' | translate }}
              </a>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="card corner-label">
            <i class="corner-icon far fa-heart icon-white"></i>
            <app-donation-block
              *ngIf="!loading"
              #donationBlock
              [donation]="donation"
              [initialSettings]="defaultSettings"
              (donationChanged)="updateDonation($event)"
              [childRelated]="donation.acf.child_related"
              (donationTypeEmitter)="setDonationType($event)"
              [existingDonation]="existingDonation"
              [hasAlreadyDonated]="hasAlreadyDonated"
              [sponsorChild]="sponsorChild"
              [campaignName]="campaignName"
            >
            </app-donation-block>
            <div class="btn-container end">
              <app-button
                *ngIf=" !isSending && (!hasAlreadyDonated || (hasAlreadyDonated && donationType))"
                cssClasses=""
                (buttonPressed)="submitDonation()"
                [isDisabled]="loading || !donationStatus.valid"
              >
                <div class="btn-with-img-container">
                  <i class="far fa-heart icon-button"></i>
                  <p>{{ 'BUTTON.donate' | translate }}</p>
                </div>
              </app-button>
              <app-loader
                *ngIf="isSending"
                size="small"
                color="blue"
                direction="horizontal-reverse"
                text="DONATE.sending"
              >
              </app-loader>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="!donation && !loading" class="col-12">
        <div class="card">
          <h3>{{ "DONATE.noCampaign" | translate}}</h3>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #alertpopup>
  <div class="alert-container">
    <h2>{{ 'DONATE.modal.' + popupType + '.title' | translate }}</h2>
    <p>{{ 'DONATE.modal.' + popupType + '.message' | translate }}</p>
    <div class="button-container">
      <app-button (buttonPressed)="closeModal()">
        {{ 'DONATE.modal.' + popupType + '.stop' | translate }}</app-button
      >
      <app-button
        [cssClasses]="'is-primary'"
        (buttonPressed)="cancelDonation()"
      >
        {{ 'DONATE.modal.' + popupType + '.continue' | translate }}</app-button
      >
    </div>
  </div>
</ng-template>
