import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { CacheService } from './cache.service';

/**
 * The auth interceptor intercepts every HTTP cal,
 * if a user is logged in it will add an Authorization header with the JWT token to the call.
 *
 * @export
 * @class AuthInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private readonly customErrorRoutes = {
    '/api/com/auth/account': 'ERROR.request.failed',
    '/api/com/auth/contact': 'ERROR.request.failed',
    '/api/com/auth/paymentProfile': 'ERROR.request.failed',
    '/api/com/auth/beneficiaries': 'ERROR.request.failed',
    '/api/com/auth/correspondence/write/letter/preview':
      'ERROR.request.preview',
    '/api/com/auth/correspondence/write/card/preview': 'ERROR.request.preview',
  };

  constructor(
    private translateService: TranslateService,
    private toastrService: ToastrService,
    private cache: CacheService
  ) {}

  /**
   * Handles token
   *
   * @param {HttpRequest<any>} req
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   * @memberof AuthInterceptor
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let request = req;
    if (this.cache.exists('id_token', 'local')) {
      request = req.clone({
        headers: req.headers.set(
          'Authorization',
          `Bearer ${this.cache.get<string>('id_token', 'local')}`
        ),
      });
    }

    return next.handle(request).pipe(
      catchError((error) => {
        if (error.status >= 500 || error.status === 0) {
          let translationKey = 'ERROR.badRequest';
          const pathName = new URL(request.urlWithParams).pathname;

          if (Object.keys(this.customErrorRoutes).includes(pathName)) {
            translationKey = this.customErrorRoutes[pathName];
          }

          return this.translateService.get(translationKey).pipe(
            switchMap((message) => {
              this.toastrService.error(message);
              return throwError(error);
            })
          );
        }

        return throwError(error);
      })
    );
  }
}
