import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})

// lang = language.. you can use 'en' for English
export class DateService {
  public static formatDate(date, lang = 'nl') {
    moment.locale(lang);
    return moment(date).format('D MMMM YYYY');
  }
}
