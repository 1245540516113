import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-multi-column-text-widget',
  templateUrl: './multi-column-text-widget.component.html',
  styleUrls: ['./multi-column-text-widget.component.scss'],
})
export class MultiColumnTextWidgetComponent implements OnInit {
  @Input() widget: { data: [{ column_text: string }] };

  widgetColumns: number;

  ngOnInit() {
    if (this.widget && this.widget.data) {
      this.widgetColumns = this.widget.data.length;
    }
  }
}
