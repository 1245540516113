import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { DonatePage } from './pages/donate/donate.page';
import { DonationEndPage } from './pages/donation-end/donation-end.page';
import { ReferalPage } from './pages/referal/referal.page';
import { SubmitMailChange } from './pages/submit-mail-change/submit-mail-change.page';
import { AuthGuard } from './services/auth/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'children',
    pathMatch: 'full',
  },
  // {
  //   path: 'home',
  //   canActivate: [AuthGuard],
  //   loadChildren: './pages/home/home.module#HomePageModule',
  // },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'registreer',
    loadChildren: () =>
      import('./pages/register/register.module').then(
        (m) => m.RegisterPageModule
      ),
  },
  {
    path: 'resetPassword',
    loadChildren: () =>
      import('./pages/setpassword/setpassword.module').then(
        (m) => m.SetPasswordPageModule
      ),
  },
  {
    path: 'referalPassword',
    loadChildren: () =>
      import('./pages/setpassword/setpassword.module').then(
        (m) => m.SetPasswordPageModule
      ),
  },
  {
    path: 'setPassword',
    loadChildren: () =>
      import('./pages/setpassword/setpassword.module').then(
        (m) => m.SetPasswordPageModule
      ),
  },
  {
    path: 'forgetPassword',
    loadChildren: () =>
      import('./pages/forgetpassword/forgetpassword.module').then(
        (m) => m.ForgetPasswordPageModule
      ),
  },
  {
    path: 'profiel',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/profile/profile.module').then((m) => m.ProfilePageModule),
  },
  {
    path: 'children',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/children/children.module').then(
        (m) => m.ChildrenPageModule
      ),
  },
  {
    path: 'betalingen',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/payments/payments.module').then(
        (m) => m.PaymentsPageModule
      ),
  },
  {
    path: 'kindgiften',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/child-gifts/child-gifts.module').then(
        (m) => m.ChildGiftsPageModule
      ),
  },
  {
    path: 'child-detail',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/child-detail/child-detail.module').then(
        (m) => m.ChildDetailPageModule
      ),
  },
  {
    path: 'referal',
    component: ReferalPage,
  },
  {
    path: 'write-letter',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./write-letter/write-letter.module').then(
        (m) => m.WriteLetterModule
      ),
    data: {
      keepWriteLetterData: false,
    },
  },
  {
    path: 'donate',
    children: [
      { path: 'success', component: DonationEndPage, canActivate: [AuthGuard] },
      {
        path: 'success-change',
        component: DonationEndPage,
        canActivate: [AuthGuard],
      },
      { path: 'failed', component: DonationEndPage, canActivate: [AuthGuard] },
      { path: '', component: DonatePage, canActivate: [AuthGuard] },
    ],
  },
  {
    path: 'change-email',
    component: SubmitMailChange,
  },
  { path: '**', redirectTo: 'children' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
