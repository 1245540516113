<div class="inbox bg-white">
  <div class="row received">
    <div class="row-header">
      <h2 (click)="toggleExpand('received')">
        {{ 'INBOX.receivedHeader' | translate }}
      </h2>

      <div tooltip="{{ 'BUTTON.refresh_inbox' | translate }}">
        <i (click)="refresh()" class="fas fa-sync-alt icon large u-margin-right-10 icon-primary u-pointer"
          [ngClass]="{'fa-spin': isRefreshing}"></i>
      </div>

      <app-tooltip class="inbox-tooltip" location="inbox" [name]="name"></app-tooltip>
      <a class="expand-button" *ngIf="!receivedExpanded" (click)="toggleExpand('received')">
        <i class="fa fa-chevron-down"></i>
      </a>
      <a class="expand-button" *ngIf="receivedExpanded" (click)="toggleExpand('received')">
        <i class="fa fa-chevron-up"></i>
      </a>
    </div>
    <div class="row-content" [ngClass]="{ show: receivedExpanded }" *ngIf="!loading">
      <h4 *ngIf="receivedLetters.length === 0" class="pt-4 notranslate has-text-centered">
        {{ 'LETTER.noMail' | translate: { name: name } }}
      </h4>
      <!-- List -->
      <div class="row-list">
        <ng-container *ngFor="let letter of receivedLetters | slice: 0:receivedListLimit">
          <ng-container *ngTemplateOutlet="listItem; context: { letter: letter, received:true }"></ng-container>
        </ng-container>
      </div>
      <!-- End list -->
      <app-button class="load-more-button" *ngIf="letters && letters.length > 0" (buttonPressed)="loadMore('received')"
        [isDisabled]="receivedListLimit >= receivedLetters.length">
        <div>{{ 'BUTTON.loadMore' | translate }}</div>
      </app-button>
    </div>
  </div>
  <div class="row sent">
    <div class="row-header">
      <h2 (click)="toggleExpand('sent')">
        {{ 'INBOX.sentHeader' | translate }}
      </h2>

      <div tooltip="{{ 'BUTTON.refresh_outbox' | translate }}">
        <i (click)="refresh()" class="fas fa-sync-alt icon large u-margin-right-10 icon-primary u-pointer"
          [ngClass]="{'fa-spin': isRefreshing}"></i>
      </div>

      <app-tooltip class="inbox-tooltip" location="sent" [name]="name"></app-tooltip>
      <a class="expand-button" *ngIf="!sentExpanded" (click)="toggleExpand('sent')">
        <i class="fa fa-chevron-down"></i>
      </a>
      <a class="expand-button" *ngIf="sentExpanded" (click)="toggleExpand('sent')">
        <i class="fa fa-chevron-up"></i>
      </a>
    </div>
    <div class="row-content" [ngClass]="{ show: sentExpanded }" *ngIf="!loading">
      <div class="send-card-button">
        <app-button (buttonPressed)="writeLetter()">
          <div>{{ 'BUTTON.sendCard' | translate }}</div>
        </app-button>
      </div>
           <h4 *ngIf="sentLetters.length === 0" class="notranslate has-text-centered">
          {{ 'LETTER.noSent' | translate: { name: name } }}
        </h4>
      <!-- List -->
      <div class="row-list" *ngIf="sentLetters.length > 0">
        <ng-container *ngFor="let letter of sentLetters | slice: 0:sentListLimit">
          <ng-container *ngTemplateOutlet="listItem; context: { letter: letter, received:false }"></ng-container>
        </ng-container>
      </div>
      <!-- End list -->
      <app-button class="load-more-button" *ngIf="letters && letters.length > 0" (buttonPressed)="loadMore('sent')"
        [isDisabled]="sentListLimit >= sentLetters.length">
        <div>{{ 'BUTTON.loadMore' | translate }}</div>
      </app-button>
    </div>
  </div>
</div>

<!-- List item -->
<ng-template #listItem let-letter="letter" let-received="received">
  <div class="content-listitem">
    <div class="icon-holder">
      <div class="item-icon bg-primary">
        <ng-container [ngSwitch]="letter.SBCTypeLocalDescription">
          <img alt="" *ngSwitchCase="'Christmas Card'" src="assets/icons/treeCard.svg" />
          <img alt="" *ngSwitchCase="'Postcard'" src="assets/icons/card.svg" />
          <img alt="" *ngSwitchCase="'Birthday Card'" src="assets/icons/birthdayCard.svg" />
          <img alt="" *ngSwitchDefault src="assets/icons/letter.svg" />
        </ng-container>
      </div>
      <span class="mobile-date"> {{ letter.formattedDate }}</span>
    </div>
    <div class="item-content">
      <span class="desktop-date"> {{ letter.formattedDate }}</span>
      <h3 class="letter-title notranslate" [ngClass]="{'is-unread':!letter.Opened && received}">
        {{ letter.message | translate: { name: name } }}
      </h3>
      <a *ngIf="letter.SourceSystem !== 'Scanner'" (click)="
      openLetterNewTab(letter.Supporter_GlobalID, letter.GlobalPartnerSBCID, letter.TimestampSentOrReceived)
        "><i class="fa fa-download"></i>
        {{ 'BUTTON.download' | translate }}
      </a>
      <span *ngIf="letter.SourceSystem === 'Scanner'">
        {{ 'LETTER.scanned' | translate }}
      </span>
    </div>
  </div>
</ng-template>

<!-- download documents -->
<ng-template #previewpopup>
  <div class="alert-container">
    <div class="level">
      <h2 class="level-left">{{ 'PDF.MODAL.preview' | translate }}</h2>
      <i class="level-right fas fa-times icon large txt-primary" (click)="closeModal()"></i>
    </div>
    <div *ngIf="!mailDownloadSrc">
      <app-loader size="small" color="blue" class="col-12"></app-loader>
    </div>
    <div class="pdfViewer" *ngIf="mailDownloadSrc">
      <pdf-viewer [src]="mailDownloadSrc" [render-text]="true" style="display: block;"></pdf-viewer>
    </div>
    <div class="btn-container between" *ngIf="mailDownloadSrc">
      <app-button (buttonPressed)="downloadLetter()">
        <div class="btn-with-img-container">
          <i class="far fa-download icon-button"></i>
          <p>
            {{ 'PDF.MODAL.download' | translate }}
          </p>
        </div>
      </app-button>
      <app-button (buttonPressed)="printLetter()">
        <div class="btn-with-img-container">
          <i class="far fa-print icon-button"></i>
          <p>
            {{ 'PDF.MODAL.print' | translate }}
          </p>
        </div>
      </app-button>
    </div>
  </div>
</ng-template>
