import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Angulartics2 } from 'angulartics2';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { Commitment } from '../../interfaces/commitment.interface';
import { Contact } from '../../interfaces/contact.model';
import { CommitmentService } from '../../services/commitment/commitment.service';
import { PopupService } from '../../services/popup.service';
import { UserService } from '../../services/user/user.service';
/**
 * Reusable component for Cards with Children
 *
 * @export
 * @class ChildCardComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-child-card',
  templateUrl: './child-card.component.html',
  styleUrls: ['./child-card.component.scss'],
})
export class ChildCardComponent implements OnDestroy, OnInit {
  /**
   * Creates an instance of ChildCardComponent.
   * @param {Router} router
   * @memberof ChildCardComponent
   */
  constructor(
    private router: Router,
    private userService: UserService,
    private commitmentService: CommitmentService,
    private translateService: TranslateService,
    private popupService: PopupService,
    private angulartics2: Angulartics2
  ) {}

  /**
   *  commitment recieved from parent
   *
   * @type {Commitment}
   * @memberof ChildCardComponent
   */
  @Input()
  commitment: Commitment;

  /**
   * The amount of sponsor children
   * the user is sponsoring
   *
   * @type {number}
   * @memberof ChildCardComponent
   */
  @Input()
  count: number;

  /**
   * Changes the html if its a detail page
   *
   * @type {boolean}
   * @memberof ChildCardComponent
   */
  @Input()
  detail = false;

  /**
   * Needed for the use of Moment;
   *
   * @memberof ChildrenPage
   */
  moment = moment;

  /**
   * Saves the contact for writing letters
   *
   * @private
   * @type {Contact}
   * @memberof ChildCardComponent
   */
  private contact: Contact;

  /**
   * Array with all the descriptions to unsub from whe the page leaves
   *
   * @private
   * @type {Subscription[]}
   * @memberof ChildCardComponent
   */
  private subscriptions: Subscription[] = [];

  /**
   * timeouts
   *
   * @private
   * @type {NodeJS.Timeout[]}
   * @memberof ChildCardComponent
   */
  private timeOuts: NodeJS.Timeout[] = [];

  @ViewChild('previewpopup') previewpopup: TemplateRef<any>;

  public mailDownloadSrc: string;

  private clickedSub: Subscription;

  private bioBlob: Blob;

  /**
   * Redirects user to Gift page of compassion.nl
   *
   * @memberof ChildrenPage
   */
  giveGift(birthday = false) {
    if (!birthday) {
      // kindgiften pagina
      this.angulartics2.eventTrack.next({
        action: 'ToGifts',
        properties: {
          category: 'gifts',
          value: this.detail ? 'detail' : 'overview',
        },
      });
      this.router.navigate(['kindgiften'], {
        queryParams: {
          id: this.commitment.BeneficiaryResponse.Beneficiary_GlobalID,
        },
      });
    } else {
      // verjaardagdonatie
      const navigationExtras: NavigationExtras = {
        queryParams: {
          donation: 'verjaardag',
          commitment: this.commitment.BeneficiaryResponse.Beneficiary_GlobalID,
        },
      };
      this.angulartics2.eventTrack.next({
        action: 'BirthdayDonation',
        properties: {
          category: 'gifts',
          value: this.detail ? 'detail' : 'overview',
        },
      });
      this.router.navigate(['donate'], navigationExtras);
    }

    // Externe pagina
    // window.open(
    //   'https://www.compassion.nl/voor-sponsors/een-gift-voor-je-sponsorkind/',
    //   '_blank'
    // );
  }

  /**
   * Load the GPID of the logged in user
   *
   * @memberof ChildCardComponent
   */
  ngOnInit(): void {
    this.userService.getContact$().subscribe((contact) => {
      this.contact = contact;
    });
  }

  getReference(commitment: Commitment): string {
    if (commitment.isOnlyACorrespondent) {
      return !commitment.BeneficiaryResponse.nameTooLong
        ? this.translateService.instant('')
        : '';
    }
  }

  /**
   * Navigate the user to the detail page of that specific child
   * sends the object with it.
   *
   * @param {Commitment} commitment
   * @memberof ChildCardComponent
   */
  goToChild() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        id: this.commitment.BeneficiaryResponse.Beneficiary_GlobalID,
      },
      state: {
        commitment: this.commitment,
        count: this.count,
      },
    };
    this.router.navigate(['child-detail'], navigationExtras);
  }

  /**
   * Redirects user to write letter page of compassion.nl
   *
   * @memberof ChildrenPage
   */
  writeLetter(type?: 'letters' | 'cards', birthday = false) {
    const commitment: NavigationExtras = {
      queryParams: {
        type,
        cat: birthday ? 'verjaardag' : null,
        commitment: this.commitment.BeneficiaryResponse.Beneficiary_GlobalID,
      },
    };

    if (birthday) {
      commitment.queryParams = { ...commitment.queryParams };
    }

    this.angulartics2.eventTrack.next({
      action: 'WriteLetter',
      properties: {
        category: 'lettermodule',
        label: birthday ? 'BirthdayCard' : 'Letter',
        value: this.detail ? 'detail' : 'overview',
      },
    });

    this.router.navigate(['write-letter'], commitment);
  }

  /**
   * Created a downloadable pdf file
   *
   * @param {string} BeneficiaryGlobalID
   * @memberof ChildCardComponent
   */
  previewBio(BeneficiaryGlobalID: string) {
    this.popupService.createPopup(this.previewpopup);
    const bio$ = this.commitmentService
      .getBioById(BeneficiaryGlobalID)
      .subscribe((x) => {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        this.bioBlob = new Blob([x], { type: 'application/pdf' });

        // create blob url
        setTimeout(() => {
          this.mailDownloadSrc = window.URL.createObjectURL(this.bioBlob);
        }, 1000);

        this.popupService.clickedOutside$.subscribe(() => {
          this.closeModal();
        });
      });

    this.subscriptions.push(bio$);
  }

  previewBioNewTab(BeneficiaryGlobalID: string) {
    const windowReference = window.open();
    const bio$ = this.commitmentService
      .getBioURLById(BeneficiaryGlobalID)
      .subscribe((x) => {
        windowReference.location = x.url;
      });

    this.subscriptions.push(bio$);
  }

  // eslint-disable-next-line class-methods-use-this
  clickImage(imageURL: string, event: MouseEvent) {
    let shouldSkip = false;
    event.composedPath().forEach((eventItem) => {
      // @ts-ignore
      if (eventItem.tagName === 'APP-TOOLTIP') {
        shouldSkip = true;
      }
    });
    if (shouldSkip) {
      return;
    }
    window.open(imageURL.replace('w_560,h_560,g_face,c_fill/', ''), '_blank');
  }

  closeModal() {
    this.popupService.closePopup();
    this.mailDownloadSrc = undefined;
    if (this.clickedSub) {
      this.clickedSub.unsubscribe();
      this.clickedSub = undefined;
    }
  }

  printBio() {
    const iframe = <HTMLIFrameElement>document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = this.mailDownloadSrc;
    document.body.appendChild(iframe);
    iframe.contentWindow.print();
  }

  downloadBio() {
    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(this.bioBlob);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(this.bioBlob);

    const link = document.createElement('a');
    link.href = data;

    // Build the file name
    link.download = `bio_${this.commitment.BeneficiaryResponse.PreferredName.toLowerCase()}.pdf`;
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );

    this.timeOuts.push(
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100)
    );
  }

  /**
   * Unsubscribe from the observables
   */
  ngOnDestroy(): void {
    this.timeOuts.forEach((timeout) => clearTimeout(timeout));
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
