import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Angulartics2 } from 'angulartics2';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { Commitment } from '../../interfaces/commitment.interface';
import { Letter } from '../../interfaces/letter.interface';
import { DateService } from '../../services/date.service';
import { HelperService } from '../../services/helper.service';
import { LetterService } from '../../services/letter/letter.service';
import { PopupService } from '../../services/popup.service';
import { UserService } from '../../services/user/user.service';

/**
 * Reusable component for letters
 *
 * @export
 * @class InboxComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.scss'],
})
export class InboxComponent implements OnInit, OnChanges, OnDestroy {
  /**
   * Creates an instance of InboxComponent.
   * @param {LetterService} letterService
   * @memberof InboxComponent
   */
  constructor(
    private router: Router,
    private letterService: LetterService,
    private userService: UserService,
    private popupService: PopupService,
    private angulartics2: Angulartics2
  ) {}

  /**
   * List of letter
   *
   * @type {Letter[]}
   * @memberof InboxComponent
   */
  @Input()
  letters: Letter[];

  /**
   * Name of the Beneficiary
   * Will come from the @Input() commitment
   * Depriceted: used to be given as input by parent
   * @type {string}
   * @memberof InboxComponent
   */
  name: string;

  /**
   * Given by parent (child-detail.page.html)
   * Will also set name (see above) from ngOnInit()
   * @type {Commitment}
   * @memberof InboxComponent
   */
  @Input() commitment: Commitment;

  /**
   * True when loading
   *
   * @memberof InboxComponent
   */
  loading = true;

  /**
   * Checks if window is mobile size
   *
   * @type {boolean}
   * @memberof InboxComponent
   */
  isMobile = HelperService.isMobile();

  /**
   * Needed for the use of Moment;
   *
   * @memberof ChildrenPage
   */
  moment = moment;

  /**
   * List of sent letters
   *
   * @type {Letter[]}
   * @memberof InboxComponent
   */
  sentLetters: Letter[] = [];

  /**
   * List of received letters
   *
   * @type {Letter[]}
   * @memberof InboxComponent
   */
  receivedLetters: Letter[] = [];

  /**
   * Determines if list is shown
   *
   * @type {boolean}
   * @memberof InboxComponent
   */
  receivedExpanded = true;

  /**
   * Determines if list is shown
   *
   * @type {boolean}
   * @memberof InboxComponent
   */
  sentExpanded = true;

  /**
   * limit on the number of shown letters
   *
   * @type {number}
   * @memberof InboxComponent
   */
  sentListLimit = 3;

  /**
   * limit on the number of shown letters
   *
   * @type {number}
   * @memberof InboxComponent
   */
  receivedListLimit = 3;

  /**
   * Array with all the subscriptions to unsub from when the page leaves
   *
   * @private
   * @type {Subscription[]}
   * @memberof InboxComponent
   */
  private subscriptions: Subscription[] = [];

  /**
   * Array with all the timouts to unsub from when the page leaves
   *
   * @private
   * @type {NodeJS.Timeout[]}
   * @memberof InboxComponent
   */
  private timeouts: NodeJS.Timeout[] = [];

  @ViewChild('previewpopup') previewpopup: TemplateRef<any>;

  private clickedSub: Subscription;

  public mailDownloadSrc: string;

  public letterBlob: Blob;

  public timestampSentOrReceived: string;

  /** check if refreshing for animation */
  public isRefreshing = true;

  /**
   * Refresh Event be submitted
   */
  @Output() refreshLettersEmitter = new EventEmitter();

  /**
   * When window resizes, check if view is mobile
   *
   * @memberof InboxComponent
   */
  @HostListener('window:resize')
  checkMobile() {
    this.isMobile = HelperService.isMobile();
    this.sentExpanded = !HelperService.isMobile();
    this.receivedExpanded = !HelperService.isMobile();
  }

  /**
   * Get preferred name
   * @memberof InboxComponent
   */
  ngOnInit(): void {
    this.name = this.commitment.BeneficiaryResponse.PreferredName;
  }

  /**
   * Adds strings to letters everytime the data refreshes
   *
   * @memberof InboxComponent
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (this.letters && changes.letters) {
      this.isRefreshing = false;
      this.sentLetters = [];
      this.receivedLetters = [];
      this.loading = false;
      this.letters = [
        ...this.letters.map((rawLetter) => {
          const letter = { ...rawLetter };
          letter.formattedDate = DateService.formatDate(
            letter.TimestampSentOrReceived
          );
          letter.message = InboxComponent.getMessage(letter);
          if (letter.Direction === 'Beneficiary To Supporter') {
            this.receivedLetters.push(letter);
          } else {
            this.sentLetters.push(letter);
          }
          return letter;
        }),
      ];
    }
  }

  /**
   * Expands the inboxes based on given string and if the view is mobile
   *
   * @param {string} container
   * @memberof InboxComponent
   */
  toggleExpand(container: string) {
    if (this.isMobile) {
      if (container === 'sent') {
        this.sentExpanded = !this.sentExpanded;
      } else {
        this.receivedExpanded = !this.receivedExpanded;
      }
    }
  }

  /**
   * Added 3 to the limit of the list
   *
   * @param {string} list
   * @memberof InboxComponent
   */
  loadMore(list: string) {
    if (list === 'sent') {
      this.sentListLimit += 3;
    } else {
      this.receivedListLimit += 3;
    }
  }

  /**
   * Redirects user to write letter page of compassion.nl
   *
   * @memberof ChildrenPage
   */
  writeLetter() {
    const commitment: NavigationExtras = {
      queryParams: {
        type: 'letters',
        commitment: this.commitment.BeneficiaryResponse.Beneficiary_GlobalID,
      },
    };

    this.angulartics2.eventTrack.next({
      action: 'WriteLetter',
      properties: {
        category: 'lettermodule',
        label: 'WriteLetter | Detail',
      },
    });

    this.router.navigate(['write-letter'], commitment);
  }

  /**
   * opens a letterpdf in a new tab. If URL is wrong you get an error:
   * {"ErrorId":"Something went wrong, please contact your webmaster.","Message":"d79d00a9-87c8-4640-ab54-4efc3073cc4b"}
   */
  openLetterNewTab(
    SupporterGlobalID: string,
    GlobalPartnerSBCID: string,
    timestampSentOrReceived: string
  ) {
    this.angulartics2.eventTrack.next({
      action: 'DownloadLetter',
      properties: {
        category: 'lettermodule',
        label: 'DownloadLetter',
      },
    });

    this.timestampSentOrReceived = timestampSentOrReceived;
    const windowReference = window.open();
    const letter$ = this.letterService
      .getLetterURLById(SupporterGlobalID, GlobalPartnerSBCID)
      .subscribe((x) => {
        windowReference.location = x.url;
      });

    this.subscriptions.push(letter$);
  }

  /**
   * Return the Translated string based of the type of message
   *
   * @param {Letter} letter
   * @returns {string}
   * @memberof InboxComponent
   */
  static getMessage(letter: Letter): string {
    let value: string;
    if (letter.Direction === 'Beneficiary To Supporter') {
      switch (letter.SBCTypeLocalDescription) {
        case 'Christmas Card':
          value = 'LETTER.christmasReceive';
          break;
        case 'Birthday Card':
          value = 'LETTER.birtdayReceive';
          break;
        case 'Postcard':
          value = 'LETTER.postCardReceive';
          break;
        default:
          value = 'LETTER.receive';
          break;
      }
    } else {
      switch (letter.SBCTypeLocalDescription) {
        case 'Christmas Card':
          value = 'LETTER.christmasSent';
          break;
        case 'Birthday Card':
          value = 'LETTER.birtdaySent';
          break;
        case 'Postcard':
          value = 'LETTER.postCardSent';
          break;
        default:
          value = 'LETTER.sent';
          break;
      }
    }
    return value;
  }

  closeModal() {
    this.popupService.closePopup();
    this.mailDownloadSrc = undefined;
    if (this.clickedSub) {
      this.clickedSub.unsubscribe();
      this.clickedSub = undefined;
    }
  }

  printLetter() {
    const iframe = <HTMLIFrameElement>document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = this.mailDownloadSrc;
    document.body.appendChild(iframe);
    iframe.contentWindow.print();
  }

  downloadLetter() {
    this.angulartics2.eventTrack.next({
      action: 'DownloadLetter',
      properties: {
        category: 'lettermodule',
        label: 'DownloadLetter',
      },
    });
    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(this.letterBlob);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(this.letterBlob);

    const link = document.createElement('a');

    link.href = data;

    // Build the file name

    link.download = `letter_${this.name.toLowerCase()}_${moment(
      this.timestampSentOrReceived
    ).format('DD-MM-YY')}_${moment(this.timestampSentOrReceived).format(
      'HH-mm'
    )}.pdf`;

    // this is necessary as link.click() does not work on the latest firefox

    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,

        cancelable: true,

        view: window,
      })
    );

    this.timeouts.push(
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);

        link.remove();
      }, 100)
    );
  }

  /**
   * refresh the inbox with animation
   */
  refresh() {
    this.isRefreshing = true;
    this.refreshLettersEmitter.emit();
  }

  /**
   * Unsubscribe from the observables
   */
  ngOnDestroy(): void {
    this.timeouts.forEach((timeout) => clearTimeout(timeout));
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
