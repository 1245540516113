import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private menuToggleSub: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  public readonly menuToggle$ = this.menuToggleSub.asObservable();

  public toggleMenu() {
    this.menuToggleSub.next(!this.menuToggleSub.value);
  }
}
