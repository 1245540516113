import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { CacheService } from '../cache.service';
import { AuthService } from './auth.service';

/**
 * The auth guard checks if a user is logged in,
 * if true it will the user can access the route
 * if false the user will be redirected to the login page
 */
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  /**
   * Creates an instance of AuthGuard.
   * @param {AuthService} authService
   * @param {Router} router
   * @memberof AuthGuard
   */
  constructor(
    private router: Router,
    private cache: CacheService,
    private authService: AuthService
  ) {}

  /**
   * Checks if a route can be activated
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns
   * @memberof AuthGuard
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.tokenIsNotExpired()) {
      return true;
    }
    this.authService.logout();
    this.cache.set('redirect-url', state.url, 'session');
    this.router.navigateByUrl('/login');
    return false;
  }

  private tokenIsNotExpired(): boolean {
    return this.cache.exists('id_token', 'local');
  }
}
