import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { DonationFrequency } from '../interfaces/donation.interface';
import { WPCampaign } from '../interfaces/WPCampaign.interface';
import { ProxyService } from './proxy.service';

export interface Donation {
  destination: string;
  amount: number;
  method: 'Direct Debit' | 'iDeal';
  frequency: DonationFrequency;
  successURL: string;
  errorURL: string;
  bank: string;
  iban: string;
  paymentProfileId: string;
  holderName: string;
  campaign: string;
  commitment: string;
  id?: string;
  multiple?: boolean;
  wpCampaign: WPCampaign;
}

@Injectable({
  providedIn: 'root',
})
export class DonationService {
  private donationProgress$ = new BehaviorSubject<Donation>(undefined);

  public readonly donationStatus$ = this.donationProgress$.asObservable();

  constructor(private proxyService: ProxyService) {}

  setDonation(donation: Partial<Donation>): boolean {
    if (!donation) {
      this.updateDonationProgress(undefined);
      return true;
    }

    if (!DonationService.validateDonation(donation)) {
      return false;
    }

    this.updateDonationProgress(donation);
    return true;
  }

  private updateDonationProgress(donation: Partial<Donation>) {
    if (!donation) {
      this.donationProgress$.next(undefined);
      return;
    }
    const current = this.donationProgress$.value;
    const newValue = { ...current, ...donation };
    this.donationProgress$.next(newValue);
  }

  sendDonation(
    donation: Partial<Donation>
  ): Observable<{
    isSuccess: boolean;
    redirectURL: string;
    successURL: string;
    errorURL: string;
    method: string;
  }> {
    if (!DonationService.validateDonation(donation)) {
      return throwError('Donation invalid');
    }

    return this.proxyService.post<{
      isSuccess: boolean;
      redirectURL: string;
      successURL: string;
      errorURL: string;
      method: string;
    }>('auth/donations', donation);
  }

  private static validateDonation(donation: Partial<Donation>): boolean {
    if (
      !donation.amount ||
      !donation.destination ||
      !donation.errorURL ||
      !donation.successURL ||
      !donation.frequency ||
      !donation.method
    ) {
      return false;
    }

    if (
      donation.method === 'Direct Debit' &&
      (!donation.holderName || !donation.iban)
    ) {
      return false;
    }

    if (donation.method === 'iDeal' && !donation.bank) {
      return false;
    }

    if (donation.method === 'iDeal' && donation.frequency !== 'One-time') {
      return false;
    }

    return true;
  }
}
