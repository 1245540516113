import { Injectable, OnDestroy } from '@angular/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Commitment } from '../../interfaces/commitment.interface';
import { LocationInfo } from '../../interfaces/location-info';
import { HelperService } from '../helper.service';
import { PaymentService } from '../payment/payment.service';
import { ProxyService } from '../proxy.service';
import { WordpressService } from '../wordpress/wordpress.service';

/**
 * Service responsable for getting commitments
 *
 * @export
 * @class CommitmentService
 */
@Injectable({
  providedIn: 'root',
})
export class CommitmentService implements OnDestroy {
  /**
   * Saves the commitments as BehaviorSubject
   *
   * @private
   * @memberof CommitmentService
   */

  /**
   * Array with all the descriptions to unsub from whe the page leaves
   *
   * @private
   * @type {Subscription[]}
   * @memberof CommitmentService
   */
  private subscriptions: Subscription[] = [];

  /**
   * Creates an instance of CommitmentService.
   * @param {HttpClient} http
   * @memberof CommitmentService
   */
  constructor(
    private proxyService: ProxyService,
    private wordpressService: WordpressService,
    private paymentService: PaymentService
  ) {}

  /**
   * Return commitments if not defined
   * If observable is defined it wil return it and make a call to refresh the data
   * @returns {Observable<Commitment[]>}
   * @memberof CommitmentService
   */
  getCommitments(): Observable<Commitment[]> {
    const commitments$ = this.proxyService.get<Commitment[]>(
      `auth/beneficiaries`
    );

    const wpSettings$ = this.wordpressService.getSettings$();
    const recurring$ = this.paymentService.getRecurringPayments$();

    return combineLatest([commitments$, wpSettings$, recurring$]).pipe(
      map(([rawCommitments, wpSettings, recurring]) => {
        const birthdayDestinationId = wpSettings.birthday_donation_id;
        const childIds = [
          ...recurring
            .filter(
              (payment) =>
                payment.soco__Destination_lookup__c === birthdayDestinationId
            )
            .map((payment) => payment.Beneficiary_GlobalID__c),
        ];

        return rawCommitments
          .map((rawC) => {
            const commitment = { ...rawC };
            commitment.getsBirthdayGift =
              childIds.indexOf(
                commitment.BeneficiaryResponse.Beneficiary_GlobalID
              ) >= 0;
            commitment.BeneficiaryResponse.smallImageUrl = HelperService.getImage(
              commitment.BeneficiaryResponse.FullBodyImageURL
            );
            commitment.BeneficiaryResponse.largeImageUrl = HelperService.getImage(
              commitment.BeneficiaryResponse.FullBodyImageURL,
              '560',
              '560',
              'fill'
            );
            commitment.BeneficiaryResponse.ageInYears = HelperService.getYears(
              commitment.BeneficiaryResponse.BirthDate
            );
            commitment.BeneficiaryResponse.daysTillBirthday = HelperService.getDaysToBirthDay(
              commitment.BeneficiaryResponse.BirthDate
            );
            commitment.BeneficiaryResponse.nameTooLong =
              commitment.BeneficiaryResponse.PreferredName.length > 8;
            return commitment;
          })
          .sort((a, b) => {
            // Sorts commitments by name
            if (
              a.BeneficiaryResponse.PreferredName <
              b.BeneficiaryResponse.PreferredName
            ) {
              return -1;
            }
            return 1;
          })
          .sort((a, b) => {
            // Put children having a birthday first
            if (a.birthdayNearFuture) {
              return -1;
            }
            return 0;
          });
      })
    );
  }

  /**
   * Get the current selected commitment by id;
   *
   * @param {string} id
   * @returns {Observable<any>}
   * @memberof CommitmentService
   */
  getCommitmentById(
    id: string
  ): Observable<{ commitment: Commitment; count: number }> {
    return this.getCommitments().pipe(
      map((commitments) => {
        if (commitments) {
          return {
            commitment: commitments.filter(
              (c) => c.BeneficiaryResponse.Beneficiary_GlobalID === id
            )[0],
            count: commitments.length,
          };
        }
        return {
          commitment: undefined,
          count: 0,
        };
      })
    );
  }

  /**
   * Get a bio by id
   *
   * @param {string} BeneficiaryGlobalID
   * @returns {Observable<Blob>}
   * @memberof CommitmentService
   */
  getBioById(BeneficiaryGlobalID: string): Observable<Blob> {
    return this.proxyService.get<Blob>(`/auth/bio/${BeneficiaryGlobalID}`, {
      responseType: 'blob' as 'json',
    });
  }

  getBioURLById(BeneficiaryGlobalID: string): Observable<{ url: any }> {
    return this.proxyService.get<{ url: any }>(
      `/auth/bio/${BeneficiaryGlobalID}`,
      {
        params: { type: 'url' },
      }
    );
  }

  /**
   * Get the location details and the weather
   *
   * @param {string} icpId
   * @returns {Observable<LocationInfo>}
   * @memberof CommitmentService
   */
  getLocationDetails(icpId: string): Observable<LocationInfo> {
    return this.proxyService.get<LocationInfo>(
      ['auth', 'locationDetails', icpId].join('/')
    );
  }

  /**
   * Unsubscribe from the observables
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
