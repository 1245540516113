import { DOCUMENT } from '@angular/common';
import {
  Component,
  HostBinding,
  Inject,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { of, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { AuthService } from './services/auth/auth.service';
import { LanguageService } from './services/language/language.service';
import { MenuService } from './services/menu.service';
import { PaymentService } from './services/payment/payment.service';
import { UserService } from './services/user/user.service';
import { WordpressService } from './services/wordpress/wordpress.service';
import { WriteLetterService } from './services/write-letter.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  isLoggedIn: boolean = false;

  @HostBinding('class.menu-open') menuOpen: boolean = false;

  /**
   * Array with all the descriptions to unsub from whe the page leaves
   *
   * @private
   * @type {Subscription[]}
   * @memberof AppComponent
   */
  private subscriptions: Subscription[] = [];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    private auth: AuthService,
    private renderer: Renderer2,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private languageService: LanguageService,
    private menuService: MenuService,
    private wordpressService: WordpressService,
    private writeLetterService: WriteLetterService,
    private sanitizer: DomSanitizer,
    private paymentService: PaymentService // ! Imported with a reason, even if it's not used
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.userService.isBetaUser$
      .pipe(distinctUntilChanged())
      .subscribe((status) => {
        if (status) {
          console.warn('⚠️ Beta mode enabled ⚠️');
        }
      });

    this.wordpressService.getGoogleAnalyticsToken().subscribe((result) => {
      if (!result) {
        return;
      }

      this.initializeTracking(result.id);
    });

    this.initPiwikPro('293906c5-d598-4a46-8758-c23b2bdbbec2');

    this.angulartics2GoogleAnalytics.startTracking();

    this.languageService.setInitialAppLanguage();

    this.subscriptions.push(
      this.auth.getLoggedIn$().subscribe((val) => {
        this.isLoggedIn = val;
      })
    );

    this.subscriptions.push(
      this.menuService.menuToggle$.subscribe((isOpen) => {
        this.menuOpen = isOpen;
      })
    );

    this.subscriptions.push(
      this.auth
        .getLoggedIn$()
        .pipe(
          switchMap((val) => {
            if (val) {
              return this.userService.getContact$();
            }
            return of(null);
          })
        )
        .subscribe((val) => {
          let name = '';
          let email = '';
          if (val) {
            name = `MyCompassion: ${val.FirstName} (${val.Supporter_GPID__c})`;
            email = `${val.Email}`;
          }

          if (
            // @ts-ignore
            window.Tawk_API &&
            // @ts-ignore
            window.Tawk_API !== {} &&
            // @ts-ignore
            typeof window.Tawk_API.setAttributes === 'function'
          ) {
            // @ts-ignore
            window.Tawk_API.setAttributes({
              name,
              email,
            });
          }
        })
    );

    this.subscriptions.push(
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          map(() => {
            let route = this.activatedRoute;
            while (route.firstChild) {
              route = route.firstChild;
            }
            return route;
          }),
          filter((route: ActivatedRoute) => route.outlet === 'primary'),
          switchMap((route: ActivatedRoute) => route.data)
        )
        .subscribe((data: any) => {
          const { keepWriteLetterData } = data;
          if (keepWriteLetterData !== false) {
            // "keepWriteLetterData !== false" if you are not in the write-letter module
            this.writeLetterService.resetProgress();
          }
        })
    );
  }

  private initializeTracking(gaId: string) {
    if (!gaId) {
      return;
    }

    const node = this.renderer.createElement('script');
    node.innerHTML = `
(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
})(window,document,'script','//www.google-analytics.com/analytics.js','ga');

ga('create', '${gaId}', 'auto');
ga('set', 'anonymizeIp', true);
      `;

    this.renderer.appendChild(this.document.head, node);
    this.angulartics2GoogleAnalytics.startTracking();
  }

  /**
   * Unsubscribe from the observables
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  private initPiwikPro(id: string) {
    if (!environment.features.loadPiwik) {
      return;
    }

    const scriptNode = this.renderer.createElement('script');
    scriptNode.innerHTML = `(function(window, document, dataLayerName, id) {
window[dataLayerName]=window[dataLayerName]||[],window[dataLayerName].push({start:(new Date).getTime(),event:"stg.start"});var scripts=document.getElementsByTagName('script')[0],tags=document.createElement('script');
function stgCreateCookie(a,b,c){var d="";if(c){var e=new Date;e.setTime(e.getTime()+24*c*60*60*1e3),d="; expires="+e.toUTCString()}document.cookie=a+"="+b+d+"; path=/"}
var isStgDebug=(window.location.href.match("stg_debug")||document.cookie.match("stg_debug"))&&!window.location.href.match("stg_disable_debug");stgCreateCookie("stg_debug",isStgDebug?1:"",isStgDebug?14:-1);
var qP=[];dataLayerName!=="dataLayer"&&qP.push("data_layer_name="+dataLayerName),isStgDebug&&qP.push("stg_debug");var qPString=qP.length>0?("?"+qP.join("&")):"";
tags.async=!0,tags.src="https://cnl.containers.piwik.pro/"+id+".js"+qPString,scripts.parentNode.insertBefore(tags,scripts);
!function(a,n,i){a[n]=a[n]||{};for(var c=0;c<i.length;c++)!function(i){a[n][i]=a[n][i]||{},a[n][i].api=a[n][i].api||function(){var a=[].slice.call(arguments,0);"string"==typeof a[0]&&window[dataLayerName].push({event:n+"."+i+":"+a[0],parameters:[].slice.call(arguments,1)})}}(i[c])}(window,"ppms",["tm","cm"]);
})(window, document, 'dataLayer', '${id}');`;

    const noScriptNode = this.renderer.createElement('noscript');
    const iframeNode = this.renderer.createElement('iframe');
    this.renderer.setAttribute(
      iframeNode,
      'src',
      `https://cnl.containers.piwik.pro/${id}/noscript.html`
    );
    this.renderer.setAttribute(iframeNode, 'height', '0');
    this.renderer.setAttribute(iframeNode, 'width', '0');
    this.renderer.setAttribute(
      iframeNode,
      'style',
      'display:none;visibility:hidden'
    );
    this.renderer.appendChild(noScriptNode, iframeNode);

    this.renderer.insertBefore(
      this.document.body,
      noScriptNode,
      this.document.body.firstChild
    );
    this.renderer.insertBefore(
      this.document.body,
      scriptNode,
      this.document.body.firstChild
    );
  }
}
