import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';
import { CommitmentService } from '../../services/commitment/commitment.service';
import { MenuService } from '../../services/menu.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  /**
   * array with the subscriptions, to unsubscribe on destroy
   *
   * @private
   * @type {Subscription[]}
   * @memberof HeaderPcComponent
   */
  private subscriptions: Subscription[] = [];

  navItems: {
    name: string;
    url?: string;
  }[] = [
    { name: 'LINK.kids', url: 'children' },
    { name: 'LINK.payments', url: 'betalingen' },
    { name: 'LINK.profile', url: 'profiel' },
  ];

  isLoggedIn: boolean = false;

  isOpen: boolean = false;

  /**
   * Creates an instance of HeaderPcComponent.
   * @param {AuthService} auth
   * @param {UserService} userService
   * @param {Router} router
   * @memberof HeaderPcComponent
   */
  constructor(
    private auth: AuthService,
    private menuService: MenuService,
    private commitmentService: CommitmentService,
    private router: Router
  ) {}

  /**
   * Starts a observable to check if the user is logged in or not
   *
   * @memberof HeaderPcComponent
   */
  ngOnInit() {
    this.subscriptions.push(
      this.auth.getLoggedIn$().subscribe((val) => {
        this.isLoggedIn = val;
      })
    );

    const sub1$ = this.commitmentService
      .getCommitments()
      .subscribe((beneficiaries) => {
        if (beneficiaries.length > 1) {
          this.navItems = [
            ...this.navItems.map((item) => {
              if (item.url !== 'children') {
                return item;
              }
              const navItem = { ...item };
              navItem.name = 'LINK.kids_plural';
              return navItem;
            }),
          ];
        } else if (beneficiaries.length < 1) {
          this.navItems = [
            ...this.navItems.filter((item) => item.url !== 'children'),
          ];
        }
      });
    this.subscriptions.push(sub1$);

    this.subscriptions.push(
      this.menuService.menuToggle$.subscribe((isOpen) => {
        this.isOpen = isOpen;
      })
    );
  }

  @HostListener('window:resize')
  checkMobile() {
    if (window.innerWidth >= 1024 && this.isOpen) {
      this.toggleMenu();
    }
  }

  toggleMenu(): void {
    this.menuService.toggleMenu();
  }

  changeTo(url: string, event: MouseEvent) {
    event.preventDefault();
    if (this.isOpen) {
      this.toggleMenu();
    }
    this.router.navigate([url]);
  }

  /**
   * Unsubscribe from the observables
   */
  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  logout() {
    this.auth.logout();
    location.reload();
  }

  navigate() {
    window.location.assign('https://www.compassion.nl');
  }
}
