<div class="donation-block" *ngIf="donation">

  <div *ngIf="hasAlreadyDonated && !donationType; else elseBlock">

    <h3>
      {{donation.acf.donation_title_choice_donation}}
    </h3>
    <p class="section" [innerHTML]="donation.acf.donation_description_choice_donation"> </p>
    <app-button (buttonPressed)="setDonationType('extra')">
      {{'DONATE.write-letter.BUTTON.yes-extra-gift' | translate}}
    </app-button>

    <app-button (buttonPressed)="setDonationType('change')">
      {{'DONATE.write-letter.BUTTON.change-gift' | translate}}
    </app-button>
  </div>
  <ng-template #elseBlock>
    <div>
      <div class="u-margin-bottom-1">
        <div>
          <div *ngIf="!hasAlreadyDonated && !donationType">
            <!-- new donation -->
            <h3 *ngIf="donation.acf.donation_title_new_donation">
              {{donation.acf.donation_title_new_donation}}
            </h3>
            <p *ngIf="sponsorChild && donation.acf.donation_description_new_donation"
              [innerHTML]="donation.acf.donation_description_new_donation">
            </p>
            <p *ngIf="!sponsorChild" [innerHTML]="donation.acf.donation_description_new_donation">
            </p>
          </div>
        </div>
        <div *ngIf="donationType === 'extra'">
          <div *ngIf="hasAlreadyDonated">
            <!-- extra donation -->
            <h3 *ngIf="donation.acf.donation_title_extra_donation">
              {{donation.acf.donation_title_extra_donation}}
            </h3>
            <p *ngIf="donation.acf.donation_description_extra_donation"
              [innerHTML]="donation.acf.donation_description_extra_donation"> </p>
          </div>
        </div>
        <div *ngIf="donationType === 'change'">
          <div *ngIf="hasAlreadyDonated">
            <!-- change donation -->
            <h3 *ngIf="donation.acf.donation_title_change_donation">
              {{donation.acf.donation_title_change_donation}}
            </h3>
            <p *ngIf="donation.acf.donation_description_change_donation"
              [innerHTML]="donation.acf.donation_description_change_donation"> </p>
          </div>
        </div>
      </div>

      <div [ngClass]="{'u-display-flex center': buttons.length === 1}" class="u-margin-bottom-1">
        <div *ngIf="buttons.length > 1">
          <app-button *ngFor="let button of buttons" [cssClasses]="button.isSelected ? 'is-primary' : ''"
            (buttonPressed)="buttonClicked(button.value)" class="u-margin-right-10">
            {{ button.buttonText}}
          </app-button>
        </div>
        <div *ngIf="buttons.length === 1" class="u-margin-right-10">
          <h3>{{ buttons[0].buttonText | translate}}</h3>
        </div>

        <div class="field custom-amount" [ngClass]="{'shown': customAmount.enabled, 'hidden': !customAmount.enabled}">
          <p class="control has-icons-left is-medium donation-input">
            <input class="input is-medium" (change)="setCustomValue()"
              [ngClass]="{'is-danger': customAmount.enabled && !customAmount.valid}"
              [placeholder]="'DONATION.placeholderother' | translate" type="number" [(ngModel)]="customAmount.value">
            <span class="icon small is-left"> </span>
          </p>
          <p class="has-text-danger" *ngIf="donation.acf.minimal_amount && customAmount.value && customAmount.enabled &&!customAmount.valid && customAmount.value < donation.acf.minimal_amount" [innerHTML]="donation.acf.minimal_amount_error ? donation.acf.minimal_amount_error :  ('DONATION.amount.minimal_error'| translate: {amount: donation.acf.minimal_amount})"></p>
          <p class="has-text-danger" *ngIf="donation.acf.maximum_amount && customAmount.value && customAmount.enabled &&!customAmount.valid && customAmount.value > donation.acf.maximum_amount" [innerHTML]="donation.acf.maximum_amount_error ? donation.acf.maximum_amount_error :  ('DONATION.amount.maximal_error'| translate: {amount: donation.acf.maximum_amount})"></p>
          <p *ngIf="donation.acf.custom_amount_description" [innerHTML]="donation.acf.custom_amount_description"></p>
        </div>
      </div>
      <div class="u-margin-bottom-1" *ngIf="!donationType && donation.acf.recurrencies.length > 0">
        <h3 class="u-margin-bottom-1"> {{ 'DONATION.frequency' | translate }}</h3>
        <div class="u-flex">
          <div *ngFor="let recurrency of donation.acf.recurrencies">
            <app-button [cssClasses]="frequency === recurrency ? 'is-primary': ''" class="u-margin-right-10"
              (buttonPressed)="setFrequency(recurrency)">
              {{ 'DONATION.'+ recurrency | translate }}
            </app-button>
          </div>

        </div>
      </div>
      <div class="u-margin-bottom-1">
        <h3>{{ 'DONATION.payment-method' | translate }}</h3>
        <div class="payment-methods">
          <div class=" payment-method direct-debit">
            <input class="is-checkradio" id="payment-direct-debit" type="radio" (click)="switchRadio('Direct Debit')"
              [checked]="type ==='Direct Debit'">
            <label for="payment-direct-debit"> {{'DONATION.directdebit' |translate}}</label>

            <div class="columns options automatic-options" *ngIf="!accountnumberEdit"
              [ngClass]="{'shown': type !== 'iDeal', 'hidden': type ==='iDeal'}">
              <div class="column is-half u-padding-left-30 u-padding-top-0">
                <div class="card columns u-border-top u-padding-0">
                  <div class="column is-10">
                    <div class="column u-padding-0">
                      {{iban?.value}}
                    </div>
                    <div class="column u-padding-0">
                      {{holderName}}
                    </div>
                  </div>
                  <div *ngIf="donationType !== 'change'" class="column is-2 has-text-right">
                    <i class="far fa-edit fa-lg u-pointer" (click)="editAccount()"></i>
                  </div>
                </div>
              </div>
            </div>


            <div class="columns options edit-automatic-options" *ngIf="accountnumberEdit"
              [ngClass]="{'shown': type !== 'iDeal', 'hidden': type ==='iDeal'}">
              <div class="column is-half u-padding-left-30 u-padding-top-0">
                <div class="card columns u-border-top u-padding-0 is-mobile">
                  <div class="column is-12">
                    <div class="column u-padding-0">
                      <div class="float-right has-text-right">
                        <i class="far fa-times fa-lg u-pointer" (click)="cancelEdit()"></i>
                      </div>
                      <p class="change-text" [innerHTML]="'DONATION.debitdescription' | translate"></p>
                      <div class="field holder">
                        <input class="input is-medium donation-input" (change)="checkIban()"
                          [ngClass]="{'is-danger':  !iban.valid}" [placeholder]="'DONATION.iban' | translate"
                          type="text" [(ngModel)]="iban.value" [disabled]="type !== 'Direct Debit'" list="ibanNumber">
                        <datalist id="ibanNumber">
                          <option [value]="profile.soco__IBAN__c" *ngFor="let profile of paymentProfileList">
                        </datalist>
                      </div>
                      <div class="field holder">
                        <input class="input is-medium  donation-input" (change)="updateHolder()"
                          [ngClass]="{'is-danger': type === 'Direct Debit' && !holderName}"
                          [placeholder]="'DONATION.ibanholder' | translate" type="text" [(ngModel)]="holderName"
                          [disabled]="type !== 'Direct Debit'">
                      </div>
                    </div>
                    <div class="has-text-right">
                      <app-button cssClasses="is-primary" (buttonPressed)="stopEdit()"
                        [isDisabled]="!iban.valid|| !holderName">
                        {{ 'BUTTON.saveData' | translate }}
                      </app-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="donationType === 'extra' || !donationType" class="payment-method iDeal u-padding-top-20">
            <input class="is-checkradio" id="payment-iDeal" type="radio" (click)="switchRadio('iDeal')"
              [checked]="type ==='iDeal'" [disabled]="frequency !== 'One-time'">
            <label for="payment-iDeal"> {{'DONATION.ideal' |translate}}</label>
            <div class="options ideal-options" [ngClass]="{'shown': type ==='iDeal', 'hidden': type !=='iDeal'}">
              <div class="select">
                <select (ngModelChange)="selectBank($event)" [ngModel]="selectedBank" [disabled]="type !== 'iDeal'">
                  <option [value]="undefined">{{ 'DONATION.selectBank' | translate }}</option>
                  <option *ngFor="let bank of banks;" [value]="bank.id">{{ bank.name }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
