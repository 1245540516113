<div class="location bg-primary">
  <div class="row map">
    <h2 class="txt-white notranslate">
      {{
        'LOCATION.title'
          | translate
            : {
                name: name,
                region: locationInfo.ICP.CountryDivision,
                country: locationInfo.ICP.Country
              }
      }}
    </h2>
    <div id="chartdiv"></div>
    <h3 class="txt-white">
      {{
        'LOCATION.weather'
          | translate: { region: locationInfo.ICP.CountryDivision }
      }}
    </h3>
    <ng-container *ngTemplateOutlet="
        listItem;
        context: {
          title: 'LOCATION.temperature',
          icon: 'temp',
          weather: false
        }
      "></ng-container>
    <ng-container *ngTemplateOutlet="
        listItem;
        context: {
          title: 'LOCATION.forecast',
          icon: locationInfo.weather.id,
          weather: true
        }
      "></ng-container>
  </div>
  <div class="row information">
    <app-tooltip class="location-tooltip inverted" location="regioninfo" inverted="true"></app-tooltip>
    <h3 class="txt-white notranslate">
      {{
        'LOCATION.region'
          | translate: { name: name }
      }}
    </h3>
    <div class="information-list">
      <ng-container *ngTemplateOutlet="
      listItem;
      context: {
        title: 'LOCATION.language',
        icon: 'language',
        weather: false
      }
    "></ng-container>
      <ng-container *ngTemplateOutlet="
      listItem;
      context: {
        title: 'LOCATION.population',
        icon: 'population',
        weather: false
      }
    "></ng-container>
      <ng-container *ngTemplateOutlet="
      listItem;
      context: { title: 'LOCATION.food', icon: 'food', weather: false }
    "></ng-container>
      <ng-container *ngTemplateOutlet="
      listItem;
      context: { title: 'LOCATION.house', icon: 'home', weather: false }
    "></ng-container>
    </div>
  </div>
</div>

<ng-template #listItem let-icon="icon" let-title="title" let-weather="weather">
  <div class="location-list-item txt-white">
    <div class="img-holder">
      <img width="40" height="40" [src]="getSource(icon, weather)">
    </div>
    <div class="text">
      <h4>{{ title | translate }}</h4>
      <p [innerHTML]="getText(icon, weather)"></p>
    </div>
  </div>
</ng-template>
