import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GenderAndPluralizerTranslatePipe } from './genderAndPluralizerTranslate.pipe';
import { GenderTranslatePipe } from './genderTranslate.pipe';
import { ImagePipe } from './image.pipe';
import { PluralizerTranslatePipe } from './pluralizerTranslate.pipe';
import { SafeHTMLPipe } from './safeHTML.pipe';

@NgModule({
  imports: [CommonModule],
  exports: [
    GenderTranslatePipe,
    PluralizerTranslatePipe,
    GenderAndPluralizerTranslatePipe,
    SafeHTMLPipe,
    ImagePipe,
  ],
  declarations: [
    GenderTranslatePipe,
    PluralizerTranslatePipe,
    GenderAndPluralizerTranslatePipe,
    SafeHTMLPipe,
    ImagePipe,
  ],
})
export class PipesModule {}
