/**
 * @ignore for documentation
 */

export enum PopupButtonAction {
  Close,
  Cancel,
  Confirm,
  Next,
  Previous,
}
