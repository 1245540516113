import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, shareReplay } from 'rxjs/operators';
import { WPCampaign } from '../../interfaces/WPCampaign.interface';
import { WPDestinationIcon } from '../../interfaces/payment.interface';
import { WPSidebar, Widget } from '../../interfaces/widget.interface';
import { Country } from '../../interfaces/wpCountry.interface';
import { WPSettings } from '../../interfaces/wpsettings.interface';
import { ProxyService } from '../proxy.service';

export type WidgetLocation =
  | 'mycompassion_profile_bottom'
  | 'mycompassion_children_top'
  | 'mycompassion_children_bottom'
  | 'mycompassion_child_detail_top'
  | 'mycompassion_child_detail_bottom'
  | 'mycompassion_gifts_bottom'
  | 'mycompassion_login_bottom'
  | 'mycompassion_write_letter_step_1'
  | 'mycompassion_write_letter_step_2'
  | 'mycompassion_write_letter_step_3'
  | 'mycompassion_write_letter_thank_you'
  | 'mycompassion_write_letter_thank_you_donation'
  | 'mycompassion_child_gifts_page_top'
  | 'mycompassion_child_gifts_page_bottom';

/**
 * Service for getting Wordpress information
 *
 * @export
 * @class WordpressService
 */
@Injectable({
  providedIn: 'root',
})
export class WordpressService {
  private settingsSub: BehaviorSubject<WPSettings> = new BehaviorSubject(
    undefined
  );

  private settingsCallSent = false;

  /**
   * Creates an instance of WordpressService.
   * @param {ProxyService} proxyService
   * @memberof WordpressService
   */
  constructor(private proxyService: ProxyService) {
    // Used for initialization
    const sub = this.getSettings$().subscribe(() => sub.unsubscribe());
  }

  getSettings$(): Observable<WPSettings> {
    if (!this.settingsSub.value && !this.settingsCallSent) {
      this.settingsCallSent = true;

      this.proxyService.get<WPSettings>('/wp/settings').subscribe(
        (result) => {
          this.settingsCallSent = false;
          this.settingsSub.next({ ...result });
        },
        () => {
          this.settingsCallSent = false;
        }
      );
    }
    return this.settingsSub.asObservable().pipe(filter((obj) => !!obj));
  }

  getGoogleAnalyticsToken(): Observable<{ id: string }> {
    return this.proxyService.get<{ id: string }>('wp/google-analytics');
  }

  /**
   * Get info about countries
   *
   * @returns
   * @memberof WordpressService
   */
  getCountryInfo() {
    const url = 'auth/correspondence/countries';
    return this.proxyService.get<Country[]>(url).pipe(shareReplay());
  }

  /**
   * Get a banner with its slug according to language specs
   *
   * @param {string} slug
   * @param {string} lang
   * @returns {Observable<CTA[]>}
   * @memberof WordpressService
   */
  getBannerForLocation(
    location: WidgetLocation,
    lang: string
  ): Observable<WPSidebar> {
    return this.proxyService.get<WPSidebar>(`/wp/banners/${location}`, {
      params: { lang },
    });
  }

  getCampaignForSlug(slug: string, lang: string = 'nl') {
    return this.proxyService.get<WPCampaign>(`/wp/campaigns/${slug}`, {
      params: { lang },
    });
  }

  getCampaigns(filtered: boolean = true, lang: string = 'nl') {
    return this.proxyService.get<WPCampaign[]>(`/wp/campaigns`, {
      params: { filtered, lang },
    });
  }

  getWidgetsForLocation(
    location: WidgetLocation,
    commitment: string,
    authorized: boolean = false,
    lang: string = undefined
  ): Observable<Widget[]> {
    const params: { lang: string; commitment?: string } = { lang };
    if (commitment) {
      params.commitment = commitment;
    }
    return this.proxyService
      .get<Widget[]>(`${authorized ? 'auth/' : ''}wp/widgets/${location}`, {
        params,
      })
      .pipe(
        catchError((error) => {
          if (error && error.status === 404) {
            return of([]);
          }
          return throwError(error);
        })
      );
  }

  getDestinationIcons(
    lang: string = 'nl'
  ): Observable<Array<WPDestinationIcon>> {
    return this.proxyService.get<Array<WPDestinationIcon>>(
      `/wp/destinationicons`,
      {
        params: { lang },
      }
    );
  }
}
