import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Letter } from '../../interfaces/letter.interface';
import { ProxyService } from '../proxy.service';
/**
 * Responsible for getting the letters from user
 *
 * @export
 * @class LetterService
 */
@Injectable({
  providedIn: 'root',
})
export class LetterService implements OnDestroy {
  /**
   * Array with all the descriptions to unsub from whe the page leaves
   *
   * @private
   * @type {Subscription[]}
   * @memberof LetterService
   */
  private subscriptions: Subscription[] = [];

  /**
   * Creates an instance of LetterService.
   * @param {ProxyService} proxyService
   * @memberof LetterService
   */
  constructor(private proxyService: ProxyService) {}

  /**
   * Return letters if not defined
   * If observable is defined it wil return it and make a call to refresh the data
   *
   * @returns {Observable<Letter[]>}
   * @memberof LetterService
   */
  getLetters(globalId: string, refresh: boolean = false): Observable<Letter[]> {
    return this.proxyService
      .get<Letter[]>(`auth/letters/${globalId}`, {
        params: { noCache: `${refresh}` }, // noCache=true || noCache=false
      })
      .pipe(
        map(letters => {
          return [
            ...letters.sort((a, b) => {
              const dateA = new Date(a.TimestampSentOrReceived).getTime();
              const dateB = new Date(b.TimestampSentOrReceived).getTime();
              return dateB - dateA;
            }),
          ];
        })
      );
  }

  getLetterURLById(
    SupporterGlobalID: string,
    GlobalPartnerSBCID: string
  ): Observable<{ url: any }> {
    return this.proxyService.get<any>(
      `auth/letters/${GlobalPartnerSBCID}/${SupporterGlobalID}`,
      { params: { type: 'url' } }
    );
  }

  /**
   * Get a letter bij id so it can be downloaded
   *
   * @param {string} SupporterGlobalID
   * @param {string} GlobalPartnerSBCID
   * @returns {Observable<Blob>}
   * @memberof LetterService
   */
  getLetterById(
    SupporterGlobalID: string,
    GlobalPartnerSBCID: string
  ): Observable<Blob> {
    return this.proxyService.get<any>(
      `auth/letters/${GlobalPartnerSBCID}/${SupporterGlobalID}`,
      { responseType: 'blob' as 'json' }
    );
  }

  /**
   * Unsubscribe from the observables
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
