<ng-template #popupTemplateStep>
  <div class="content">
    <div [ngClass]="{'contentAnimationStep2': step === '2', 'contentAnimationStep3': step === '3'}">
      <h1>{{ 'digital-letter-consent.step'+step+'.title' | translate}}</h1>
      <div [innerHTML]="'digital-letter-consent.step'+step+'.content' | translate"></div>
    </div>
    <ng-container *ngTemplateOutlet="buttons"></ng-container>
  </div>
</ng-template>

<ng-template #buttons>
  <div class="btn-container">
    <app-button *ngIf="step !== '2b'" (buttonPressed)="checkButtonActions(3)" [isDisabled]="sendCall"
      [cssClasses]="step === '3' ? 'is-primary': ''">
      {{'digital-letter-consent.step'+step+'.denybuttontext' | translate}}
    </app-button>
    <app-button *ngIf="step !== '3'" [cssClasses]="'is-primary'" (buttonPressed)="checkButtonActions(2)"
      [isDisabled]="sendCall">
      {{'digital-letter-consent.step'+step+'.acceptbuttontext' | translate}}
    </app-button>
  </div>
</ng-template>