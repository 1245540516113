import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  OneTimePayment,

  RecurringPayment,
  TotalPayments
} from '../../interfaces/payment.interface';
import { AuthService } from '../auth/auth.service';
import { ProxyService } from '../proxy.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentService implements OnDestroy {
  private isLoggedIn: boolean = false;

  private recurringPaymentsSub: BehaviorSubject<
    RecurringPayment[]
  > = new BehaviorSubject<RecurringPayment[]>([]);

  /**
   * Array with all the descriptions to unsub from whe the page leaves
   *
   * @private
   * @type {Subscription[]}
   * @memberof ChildDetailPage
   */
  private subscriptions: Subscription[] = [];

  /**
   * Creates an instance of PaymentService.
   * @param {HttpClient} http
   * @param {AuthService} authService
   * @memberof PaymentService
   */
  constructor(
    private proxyService: ProxyService,
    private authService: AuthService
  ) {
    const loggedin$ = this.authService.getLoggedIn$().subscribe(res => {
      this.isLoggedIn = res;
      if (res) {
        // this.getRecuringPaymentsFromApi();
      } else {
        this.recurringPaymentsSub.next([]);
      }
    });

    this.subscriptions.push(loggedin$);
  }

  public getRecurringPayments$(): Observable<RecurringPayment[]> {
    if (
      !this.recurringPaymentsSub.value ||
      this.recurringPaymentsSub.value.length <= 0
    ) {
      this.getRecuringPaymentsFromApi();
    }

    return this.recurringPaymentsSub.asObservable();
  }

  private getRecuringPaymentsFromApi() {
    if (!this.isLoggedIn) {
      return;
    }

    const sub$ = this.proxyService
      .get<RecurringPayment[]>('auth/payments/recurring')
      .subscribe(result => {
        this.recurringPaymentsSub.next([...result]);
      });

    this.subscriptions.push(sub$);
  }

  getOneTimePaymentsForYear(
    year: number = new Date().getFullYear()
  ): Observable<OneTimePayment[]> {
    return this.authService.getLoggedIn$().pipe(
      switchMap(res =>
        res
          ? this.proxyService.get<OneTimePayment[]>('auth/payments/one-time', {
            params: { year },
          })
          : throwError('unauthorized')
      )
    );
  }

  getTotalPaymentsForYear(
    year: number = new Date().getFullYear()
  ): Observable<TotalPayments> {
    return this.authService.getLoggedIn$().pipe(
      switchMap(res =>
        res
          ? this.proxyService.get<TotalPayments>('auth/payments/total', {
            params: { year },
          })
          : throwError('unauthorized')
      )
    );
  }

  /**
   * Unsubscribe from the observables
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
