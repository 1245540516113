import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  HostListener,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GoogleCharts } from 'google-charts';
import { LocationInfo } from '../../interfaces/location-info';
import { HelperService } from '../../services/helper.service';
/**
 * Component for location info of beneficiary
 *
 * @export
 * @class LocationInfoComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-location-info',
  templateUrl: './location-info.component.html',
  styleUrls: ['./location-info.component.scss'],
})
export class LocationInfoComponent implements OnInit {
  /**
   * ICP and weather info
   *
   * @type {LocationInfo}
   * @memberof LocationInfoComponent
   */
  @Input() locationInfo: LocationInfo;

  /**
   * Name of the beneficiary
   *
   * @type {string}
   * @memberof LocationInfoComponent
   */
  @Input() name: string;

  /**
   * boolean to keep check of the screen size
   *
   * @private
   * @memberof LocationInfoComponent
   */
  private isMobile = false;

  private isTablet = false;

  getSource = LocationInfoComponent.getSourceStatic;

  /**
   * Creates an instance of LocationInfoComponent.
   * @param {TranslateService} translateService
   * @memberof LocationInfoComponent
   */
  constructor(
    private translateService: TranslateService,
    @Inject(PLATFORM_ID) private platformId: string
  ) {}

  /**
   * Check if mobile and create map
   *
   * @memberof LocationInfoComponent
   */
  ngOnInit() {
    this.checkMobile();
  }

  /**
   * Check if user is on mobile
   *
   * @memberof LocationInfoComponent
   */
  @HostListener('window:resize')
  checkMobile() {
    this.isMobile = HelperService.isMobile();

    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        this.createMap();
      });
    } else {
      this.createMap();
    }
  }

  /**
   * Generate the country map
   *
   * @memberof LocationInfoComponent
   */
  createMap() {
    GoogleCharts.load(
      () => {
        const data = GoogleCharts.api.visualization.arrayToDataTable([
          ['Lat', 'Long', 'Name'],
          [
            this.locationInfo.location.lat,
            this.locationInfo.location.lon,
            this.locationInfo.ICP.CountryDivision,
          ],
        ]);

        const options = {
          region: this.locationInfo.location.country,
          resolution: this.locationInfo.location.country ? 'provinces' : null,
          backgroundColor: '#165AB0',
          displayMode: 'markers',
          height: this.isMobile ? 147 : 225,
          keepAspectRatio: true,
          defaultColor: '#C6BDB0',
        };

        const chart = new GoogleCharts.api.visualization.GeoChart(
          document.getElementById('chartdiv')
        );

        GoogleCharts.api.visualization.events.addListener(
          chart,
          'error',
          () => {
            chart.clearChart();
            if (options.resolution) {
              options.resolution = null;
            } else if (options.region) {
              options.region = null;
            }

            chart.draw(data, options);
          }
        );
        chart.draw(data, options);
      },
      {
        packages: ['geochart'],
        mapsApiKey: 'AIzaSyCm_1iKS9aZKsRLwWworZ5itcmC_Xb8XSU',
      }
    );
  }

  /**
   * get the source of the image for the list item
   *
   * @param {(string | number)} icon
   * @param {boolean} isWeather
   * @returns {string}
   * @memberof LocationInfoComponent
   */
  static getSourceStatic(icon: string | number, isWeather: boolean): string {
    let name;
    if (isWeather) {
      switch (+icon) {
        case 800:
          name = 'sun';
          break;
        case 801:
          name = 'cloud-sun';
          break;
        case 802:
          name = 'cloud';
          break;
        case 803:
        case 804:
          name = 'clouds';
          break;
        case 300:
        case 301:
        case 302:
        case 310:
        case 311:
        case 312:
        case 313:
        case 314:
        case 321:
        case 521:
        case 522:
        case 531:
          name = 'cloud-showers-heavy';
          break;
        case 500:
        case 501:
        case 502:
        case 503:
        case 504:
          name = 'cloud-showers';
          break;
        case 200:
        case 201:
        case 202:
        case 210:
        case 211:
        case 212:
        case 221:
        case 230:
        case 231:
        case 232:
          name = 'thunderstorm';
          break;
        case 511:
        case 600:
        case 601:
        case 602:
        case 611:
        case 612:
        case 613:
        case 614:
        case 615:
        case 616:
        case 620:
        case 621:
        case 622:
          name = 'snowflake';
          break;
        case 701:
        case 711:
        case 721:
        case 731:
        case 741:
        case 751:
        case 761:
        case 762:
        case 771:
        case 781:
          name = 'fog';
          break;
        default:
          break;
      }
    } else {
      switch (icon) {
        case 'language':
          name = 'spokenLang';
          break;
        case 'population':
          name = 'population';
          break;
        case 'food':
          name = 'localFood';
          break;
        case 'home':
          name = 'household';
          break;
        case 'temp':
        default:
          name = 'thermometer-three-quarters';
          break;
      }
    }

    return `assets/icons/${name}.svg`;
  }

  /**
   * get the text for the list item
   *
   * @param {(string | number)} item
   * @param {boolean} isWeather
   * @returns {string}
   * @memberof LocationInfoComponent
   */
  getText(item: string | number, isWeather: boolean): string {
    let text;
    if (isWeather) {
      text = 'WEATHER.';
      switch (+item) {
        case 800:
          text += 'clear';
          break;
        case 801:
          text += 'semiClouded';
          break;
        case 802:
          text += 'clouded';
          break;
        case 803:
        case 804:
          text += 'heavyClouds';
          break;
        case 300:
        case 301:
        case 302:
        case 310:
        case 311:
        case 312:
        case 313:
        case 314:
        case 321:
        case 521:
        case 522:
        case 531:
          text += 'heavyRain';
          break;
        case 500:
        case 501:
        case 502:
        case 503:
        case 504:
          text += 'rain';
          break;
        case 200:
        case 201:
        case 202:
        case 210:
        case 211:
        case 212:
        case 221:
        case 230:
        case 231:
        case 232:
          text += 'thunderstorm';
          break;
        case 511:
          text += 'freezingRain';
          break;
        case 600:
        case 601:
        case 602:
        case 611:
        case 612:
        case 613:
        case 614:
        case 615:
        case 616:
        case 620:
        case 621:
        case 622:
          text += 'snow';
          break;
        case 701:
        case 741:
          text += 'fog';
          break;
        case 711:
        case 721:
          text += 'smog';
          break;
        case 731:
        case 751:
        case 761:
          text += 'sandstorm';
          break;
        case 762:
          text += 'vulcano';
          break;
        case 771:
          text += 'heavyStorm';
          break;
        case 781:
          text += 'tornado';
          break;
        default:
          break;
      }
      text = this.translateService.instant(text);
    } else {
      switch (item) {
        case 'language':
          text = this.locationInfo.ICP.PreferredLanguage;
          break;
        case 'population':
          text = this.locationInfo.ICP.Population;
          break;
        case 'food':
          text = this.locationInfo.ICP.PrimaryDiet.join(', ');
          break;
        case 'home':
          text = [
            `${this.translateService.instant('BUILDING.floor')}: ${
              this.locationInfo.ICP.HomeFloor
            }`,
            `${this.translateService.instant('BUILDING.roof')}: ${
              this.locationInfo.ICP.HomeRoof
            }`,
            `${this.translateService.instant('BUILDING.wall')}: ${
              this.locationInfo.ICP.HomeWall
            }`,
          ].join('<br/> ');
          break;
        case 'temp':
          text = `${Math.round(
            this.locationInfo.weather.temp - 273
          ).toString()}&#176;`;
          break;
        default:
          text = '';
          break;
      }
    }

    return text;
  }
}
