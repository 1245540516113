import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
/**
 * Checks wether the key is plural or not
 *
 * @export
 * @class PluralizerTranslatePipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'pluralizerTranslate',
  pure: false,
})
export class PluralizerTranslatePipe implements PipeTransform {
  /**
   * Creates an instance of PluralizerTranslatePipe.
   * @param {TranslateService} translateService
   * @memberof PluralizerTranslatePipe
   */
  constructor(private translateService: TranslateService) {}

  /**
   * adds _plural or not, depending on the amount
   * Usage: 'key' | pluralizerTranslate: amount
   *
   * @param {string} key
   * @param {number} amount
   * @returns {string}
   * @memberof PluralizerTranslatePipe
   */
  transform(key: string, params: { amount: number; name?: string }): string {
    return this.translateService.instant(
      (key +=
        params.amount > 1 ? '_plural' : params.amount === 0 ? '_zero' : ''),
      { N: params.amount, name: params.name }
    );
  }
}
