import { Component, Input } from '@angular/core';
import { ProfileCardItem } from '../../interfaces/profile-card-item.interface';

@Component({
  selector: 'app-profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.scss'],
})
export class ProfileCardComponent {
  /**
   * The item that will be shown
   */
  @Input() item: ProfileCardItem;

  /**
   * Checks if value is a string
   */
  isString = (val: any) => typeof val === 'string';
}
