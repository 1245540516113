import { Component, Input, OnInit } from '@angular/core';
import { WPFAQWidget } from '../../interfaces/faq-item.interface';

/**
 * Loads an FAQ widget from WP
 *
 * @export
 * @class FAQComponent
 */
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FAQComponent implements OnInit {
  @Input() private widget: WPFAQWidget;

  questions: Array<{
    question: string;
    answer: string;
    isOpen: boolean;
  }>;

  ngOnInit(): void {
    if (
      !this.widget ||
      !this.widget ||
      !this.widget.data ||
      !this.widget.data.post ||
      !this.widget.data.post.length
    ) {
      return;
    }

    this.questions = this.widget.data.post.map(faq => {
      return {
        question: faq.post_title,
        answer: faq.acf.faq_answer,
        isOpen: false,
      };
    });
  }

  toggleQuestion(index: number) {
    if (index > this.questions.length - 1) {
      return;
    }

    this.questions[index].isOpen = !this.questions[index].isOpen;
  }
}
