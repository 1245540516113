<div *ngIf="recurringPayments && oneTimePaymentsPerYear && !loading" class="payments-overview bg-white container">
  <div class="row division-layout is-half-print">
    <div class="row-header">
      <h3>
        {{ 'PAYMENTS.overview.receivedHeader' | translate }}
      </h3>
      <app-tooltip location="payments-overview-left"></app-tooltip>
    </div>

    <div class="row-list list-item u-padding-bottom-10" *ngFor="let paymentPerChild of recurringPayments; let i = index" [ngClass]="{
      'is-child-related': !!paymentPerChild.beneficiaryName,
      'is-not-child-related': !paymentPerChild.beneficiaryName
    }">
      <div class="u-row u-align-text-icon columns is-mobile">
        <img *ngIf="paymentPerChild.image" [src]="paymentPerChild.image"
          class="item-icon-container large u-padding-0" />
        <div class="column u-padding-0">
          <h3 class="notranslate">
            {{
            paymentPerChild.beneficiaryName
            ? paymentPerChild.beneficiaryName
            : ('PAYMENTS.overview.not-childrelated-gift' | translate)
            }}
          </h3>
          <div (click)="togglePaymentPerChild(i)" class="txt-primary txt-bold u-pointer">
            <ng-container *ngIf="expandedLeft === i && !isPrinting">
              <span class="icon is-medium"><i class="fa fa-angle-down fas icon-medium"></i></span><span
                translate>BUTTON.show-less</span>
            </ng-container>
            <ng-container *ngIf="expandedLeft !== i && !isPrinting">
              <span class="icon is-medium"><i class="fa fa-angle-right fas"></i></span><span
                translate>BUTTON.show-more</span>
            </ng-container>
          </div>
        </div>
        <div class="column icon-grid u-padding-0" *ngIf="paymentPerChild.beneficiaryName">
          <div class="u-padding-0 icon-grid-item u-txt-smaller" *ngIf="paymentPerChild.beneficiaryId">
            <img alt="" [src]="
                paymentPerChild.donations.Sponsorbijdrage !== 0
                  ? overviewIcons['Child Support'].dark
                  : overviewIcons['Child Support'].greyed
              " class="payment-icon" tooltip="{{ 'PAYMENTS.tooltip.sponsors' | translate }}" />
            <div class="txt-bold u-margin-top-05">
              {{
              'PAYMENTS.donations.sponsorbijdrage'
              | translate
              : { donation: isInteger(paymentPerChild.donations.Sponsorbijdrage) ? (paymentPerChild.donations.Sponsorbijdrage| number: '1.0-0':'nl-NL'): (paymentPerChild.donations.Sponsorbijdrage| number: '1.2-2':'nl-NL') }
              }}
            </div>
          </div>
          <div class="u-padding-0 icon-grid-item u-txt-smaller" *ngIf="paymentPerChild.beneficiaryId">
            <img alt="" [src]="
                paymentPerChild.donations.Verjaardagsgift !== 0
                  ? overviewIcons['Birthday Gift'].dark
                  : overviewIcons['Birthday Gift'].greyed
              " class="payment-icon icon-hover-state" tooltip="{{ 'PAYMENTS.tooltip.birthday' | translate }}"
              (click)="birthdayDonation(paymentPerChild.beneficiaryId)" />
            <div class="txt-bold u-margin-top-05 u-pointer" (click)="birthdayDonation(paymentPerChild.beneficiaryId)">
              {{
              'PAYMENTS.donations.verjaardagsgift'
              | translate
              : {
              donation:
              paymentPerChild.donations.Verjaardagsgift
              | number: '1.0-0':'nl-NL'
              }
              }}
            </div>
          </div>
          <div class="u-padding-0 icon-grid-item u-txt-smaller" *ngIf="paymentPerChild.beneficiaryId">
            <img alt="" [src]="
                paymentPerChild.donations.Familiegift !== 0
                  ? overviewIcons['Family Gift'].dark
                  : overviewIcons['Family Gift'].greyed
              " class="payment-icon icon-hover-state" tooltip="{{ 'PAYMENTS.tooltip.familygift' | translate }}"
              (click)="familyDonation(paymentPerChild.beneficiaryId)" />
            <div class="txt-bold u-margin-top-05 u-pointer" (click)="familyDonation(paymentPerChild.beneficiaryId)">
              {{
              'PAYMENTS.donations.familiegift'
              | translate
              : {
              donation:
              paymentPerChild.donations.Familiegift
              | number: '1.0-0':'nl-NL'
              }
              }}
            </div>
          </div>
          <div class="u-padding-0 icon-grid-item u-txt-smaller non-benificiary" *ngIf="!paymentPerChild.beneficiaryId">
            <img alt="" [src]="
                paymentPerChild.donations['Losse Gift'] !== 0
                  ? overviewIcons['Other'].dark
                  : overviewIcons['Other'].greyed
              " class="payment-icon icon-hover-state" tooltip="{{ 'PAYMENTS.tooltip.lossegift' | translate }}" />
            <div class="txt-bold u-margin-top-05">
              {{
              'PAYMENTS.donations.lossegift'
              | translate
              : {
              donation:
              paymentPerChild.donations['Losse Gift']
              | number: '1.0-0':'nl-NL'
              }
              }}
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="expandedLeft === i || isPrinting">
        <ng-container *ngFor="
            let recurringPayment of paymentPerChild.payments
              | slice
                : 0
                : (paymentListLimit.index === i || isPrinting
                    ? paymentListLimit.limit
                    : 3);
            let i = index
          ">
          <ng-container *ngTemplateOutlet="listItem; context: { payment: recurringPayment }">
          </ng-container>
        </ng-container>
        <app-button class="child-payment-container" (buttonPressed)="loadMore(i)"
          *ngIf="paymentPerChild.payments.length > 3 && !isPrinting" [isDisabled]="
            paymentListLimit.index === i &&
            paymentListLimit.limit > paymentPerChild.payments.length
          ">
          <div>{{ 'BUTTON.loadMore' | translate }}</div>
        </app-button>
      </ng-container>
    </div>
  </div>
  <div class="row right-side is-half-print">
    <div class="row-header">
      <h3>
        {{ 'PAYMENTS.overview.gift-once' | translate }}
      </h3>
      <app-tooltip location="payments-overview-right"></app-tooltip>
    </div>
    <ng-container *ngFor="let oneTimePayment of oneTimePaymentsPerYear">
      <div *ngIf="oneTimePayment.length !== 0" class="row-list list-item">
        <span>
          <h3>{{ oneTimePayment[0].year }}</h3>
        </span>
        <div (click)="togglePaymentPerYear(oneTimePayment[0].year)" class="txt-primary txt-bold u-pointer">
          <div *ngIf="expandedRight === oneTimePayment[0].year && !isPrinting">
            <span class="icon is-medium"><i class="fa fa-angle-down fas icon-medium"></i></span><span
              translate>BUTTON.show-less</span>
          </div>
          <div *ngIf="expandedRight !== oneTimePayment[0].year && !isPrinting">
            <span class="icon is-medium">
              <i class="fa fa-angle-right fas"></i>
            </span>
            <span translate>BUTTON.show-more </span>
          </div>
        </div>
        <div [ngClass]="{
            'u-hidden': expandedRight !== oneTimePayment[0].year && !isPrinting
          }">
          <ng-container *ngFor="let oneTimePaymentThisYear of oneTimePayment">
            <ng-container *ngIf="oneTimePaymentThisYear.amount !== 0">
              <ng-container *ngTemplateOutlet="
                  listItem;
                  context: { payment: oneTimePaymentThisYear }
                ">
              </ng-container>
            </ng-container>
          </ng-container>

          <app-button class="child-payment-container" (buttonPressed)="loadMore(oneTimePayment[0].year)"
            *ngIf="oneTimePayment.length > 3 && !isPrinting" [isDisabled]="
              paymentListLimit.index === oneTimePayment[0].year &&
              paymentListLimit.limit > oneTimePayment.length
            ">
            <div>{{ 'BUTTON.loadMore' | translate }}</div>
          </app-button>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<!-- List item -->
<ng-template #listItem let-payment="payment" >
  <div *ngIf="payment.length !== 0" class="u-row payment-item" >
    <div class="child-payment-container">
      <div class="u-row child-list-item">
        <div class="item-icon-container background-print">
          <img *ngIf="payment.icon && payment.icon.light" class="payment-icon" src="{{ payment.icon.light }}" />
        </div>
        <div *ngIf="!payment.beneficiaryName">
          <div class="txt-bold">
            {{ payment.type }}
          </div>
          <div class="u-row txt-bold">
            <h3 class="txt-bold">
              €{{ isInteger(payment.amount) ? (payment.amount| number: '1.0-0':'nl-NL'): (payment.amount| number: '1.2-2':'nl-NL')}}&nbsp;
            </h3>
            <h3 *ngIf="payment.frequency === 'Monthly'">
              {{ 'PAYMENTS.perMonthly' | translate }}
            </h3>
            <h3 *ngIf="payment.frequency === 'Yearly'">
              {{ 'PAYMENTS.perYearly' | translate }}
            </h3>
          </div>

          <p *ngIf="payment.nextDate">
            <span> {{ setMonth(payment.nextDate) }}, </span>
            <span>{{ setNextDate(payment.nextDate) | translate }}</span>
          </p>

          <p *ngIf="payment.endDate">
            {{
            'PAYMENTS.endDate'
            | translate: { date: formatDate(payment.endDate) }
            }}
          </p>
        </div>

        <!-- if childrelated gift in one time gifts array -->
        <div *ngIf="payment.beneficiaryName">
          <div class="txt-bold u-margin-bottom-1">
            {{ payment.type }}
          </div>
          <div class="u-row u-align-text-icon">
            <img *ngIf="payment.image" [src]="payment.image" class="item-icon-container large u-padding-0" />
            <div>
              <p class="txt-bold notranslate">{{ payment.beneficiaryName }}</p>
              <h3 class="txt-bold">
                €{{ isInteger(payment.amount) ? (payment.amount| number: '1.0-0':'nl-NL'): (payment.amount| number: '1.2-2':'nl-NL') }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
