import { Component, EventEmitter, Input, Output } from '@angular/core';
/**
 * Reusable button component
 *
 * @export
 * @class ButtonComponent
 */
@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  /**
   * Disables button
   *
   * @memberof ButtonComponent
   */
  @Input() isDisabled = false;

  @Input() cssClasses: string = '';

  /**
   * Sets type
   *
   * @memberof ButtonComponent
   */
  @Input() type = 'button';

  /**
   * Makes button blue if true
   *
   * @memberof ButtonComponent
   */
  @Input() isCTA = false;

  /**
   * Makes button pink or light blue
   *
   * @memberof ButtonComponent
   */
  @Input() gender = '';

  /**
   * shows loading spinner
   *
   * @memberof ButtonComponent
   */
  @Input() loading = false;

  /**
   * Event be submitted
   *
   * @memberof ButtonComponent
   */
  @Output() buttonPressed = new EventEmitter();

  /**
   * Send evet when pressed
   *
   * @memberof ButtonComponent
   */
  onClickButton(event) {
    this.buttonPressed.emit(event);
  }
}
