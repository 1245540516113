import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { WordpressService } from '../../services/wordpress/wordpress.service';

@Component({
  selector: 'app-donation-end',
  templateUrl: './donation-end.page.html',
  styleUrls: ['./donation-end.page.scss'],
})
export class DonationEndPage {
  /**
   * background image
   *
   * @type {string}
   * @memberof DonationEndPage
   */
  image: string;

  pageMode: 'success' | 'failed' | 'success-change' = 'success';

  campaignText: string;

  isLoading = true;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private wordpressService: WordpressService
  ) {}

  ngOnInit() {
    this.route.queryParams
      .pipe(
        switchMap((params) => {
          if (!params && !params.slug) {
            return of(undefined);
          }
          return this.wordpressService.getCampaignForSlug(params.slug);
        })
      )
      .subscribe(
        (campaign) => {
          if (campaign && campaign.acf && campaign.acf.thank_you_page_text) {
            this.campaignText = campaign.acf.thank_you_page_text;
          }
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        }
      );

    this.image = '../../../assets/project-compassion-nederland.jpg';
    this.pageMode = this.checkPageMode();
  }

  /**
   * returns the pagemode
   *
   * @private
   * @returns {('success' | 'failed')}
   * @memberof SetPasswordPage
   */
  private checkPageMode(): 'success' | 'failed' | 'success-change' {
    const { url }: { url: string } = this.router;
    if (/success-change/.test(url.toLowerCase())) {
      return 'success-change';
    }

    if (/success/.test(url.toLowerCase())) {
      return 'success';
    }

    if (/failed/.test(url.toLowerCase())) {
      return 'failed';
    }

    return 'success';
  }
}
