import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Banner, WPBannerWidget } from '../../interfaces/banner.interface';
import { UserService } from '../../services/user/user.service';

/**
 * Call to Action component
 *
 * @export
 * @class BannerComponent
 */
@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit {
  public isCompact: boolean = true;

  /**
   * Location of the CTA component
   *
   * @type {string}
   * @memberof BannerComponent
   */
  @Input() widget: WPBannerWidget;

  /**
   * For string CTA
   *
   * @type {CTA}
   * @memberof BannerComponent
   */
  cta: Banner;

  image: string;

  public targetUrl: string = '';

  /**
   * Array with all the descriptions to unsub from whe the page leaves
   *
   * @private
   * @type {Subscription[]}
   * @memberof AppComponent
   */
  private subscriptions: Subscription[] = [];

  public bannerParamaters = {
    email: '',
    gpid: '',
    globalId: '',
    sponsorName: '',
  };

  constructor(private userService: UserService) {}

  /**
   * Getting the nessecary data
   *
   * @memberof BannerComponent
   */
  ngOnInit(): void {
    if (!this.widget || !this.widget.data || !this.widget.data.fields) {
      return;
    }

    this.cta = { ...this.widget.data.fields };
    this.isCompact = !!this.cta.is_compact;
    this.targetUrl = this.replaceCurlyBraces(this.cta.url);

    const contact$ = this.userService.getContact$().subscribe((contact) => {
      this.bannerParamaters.gpid = contact.Supporter_GPID__c;
      this.bannerParamaters.email = contact.Email;
      this.bannerParamaters.globalId = contact.Supporter_GlobalID__c;
      this.bannerParamaters.sponsorName = contact.Supporter_Preferred_Name__c;
      this.targetUrl = this.replaceCurlyBraces(this.cta.url);
    });

    this.subscriptions.push(contact$);

    this.image = `url(${this.cta.banner_image.url})`;
  }

  clickButton(event: MouseEvent) {
    event.preventDefault();
    if (this.cta.open_in_new_tab) {
      window.open(this.targetUrl, '_blank');
    } else {
      window.location.replace(this.targetUrl);
    }
  }

  replaceCurlyBraces(url: string): string {
    return url
      .replace('{{gpid}}', this.bannerParamaters.gpid)
      .replace('{{email}}', this.bannerParamaters.email)
      .replace('{{globalId}}', this.bannerParamaters.globalId)
      .replace('{{sponsorName}}', this.bannerParamaters.sponsorName);
  }
}
