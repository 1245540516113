<div class="donation-end page-container">
  <div class="grid">
    <div class="columns is-centered">
      <div class="col-12 col-md-9">
        <section class="section">
          <div class="card">
            <div *ngIf="!isLoading" class="card-content">
              <h1>
                {{'DONATE.end.'+pageMode+'.title' | translate}}
              </h1>
              <h3>{{ 'DONATE.end.'+pageMode+'.subtitle' | translate }}</h3>
              <p *ngIf="campaignText" [innerHTML]="campaignText"></p>
              <p *ngIf="!campaignText" [innerHTML]="'DONATE.end.'+pageMode+'.text' | translate"></p>
              <app-button (buttonPressed)="router.navigate(['children'])">
                {{'DONATE.end.button' | translate}}
              </app-button>
              <img [src]="image">
            </div>
            <app-loader *ngIf="isLoading"></app-loader>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>
