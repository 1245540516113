import { Component, OnInit, ViewChild } from '@angular/core';
import { of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PopupButtonAction } from '../../enums/popupButtonAction';
import { AuthService } from '../../services/auth/auth.service';
import { PopupService } from '../../services/popup.service';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'app-digital-consent',
  templateUrl: './digital-consent.component.html',
  styleUrls: ['./digital-consent.component.scss'],
})
export class DigitalConsentComponent implements OnInit {
  askConsent: boolean;

  step = '0';

  sendCall = false;

  @ViewChild('popupTemplateStep') popupTemplateStep: any;

  private subscriptions: Subscription[] = [];

  PopupButtonAction: PopupButtonAction;

  constructor(
    private popupService: PopupService,
    private auth: AuthService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.auth
        .getLoggedIn$()
        .pipe(
          switchMap(val => {
            if (val) {
              return this.userService.shouldAskConsent();
            }
            return of(null);
          })
        )
        .subscribe((result: { askConsent: boolean }) => {
          if (!result) {
            return;
          }

          this.askConsent = result.askConsent;
          if (this.askConsent && this.step === '0') {
            this.showNextPopup();
          }
        })
    );
  }

  showNextPopup() {
    if (this.step === '0') {
      this.step = '1';
      this.popupService.createPopup(this.popupTemplateStep);
    } else if (this.step === '1') {
      this.step = '2';
      this.popupService.createPopup(this.popupTemplateStep);
    } else if (this.step === '2') {
      this.step = '3';
      this.popupService.createPopup(this.popupTemplateStep);
    } else if (this.step === '3') {
      this.consent(false);
    }
  }

  showAgreedPopup() {
    if (this.step === '1' || this.step === '2') {
      this.step = '2b';
      this.popupService.createPopup(this.popupTemplateStep);
    } else {
      this.consent(true);
    }
  }

  public consent(value) {
    this.sendCall = true;
    this.userService.updateConsent(value).subscribe(() => {
      this.askConsent = true;
      this.popupService.closePopup();
      this.sendCall = false;
    });
  }

  checkButtonActions(buttonaction: PopupButtonAction) {
    switch (buttonaction) {
      case PopupButtonAction.Confirm:
        this.showAgreedPopup();
        break;
      case PopupButtonAction.Next:
        this.showNextPopup();
        break;
      default:
        break;
    }
  }
}
