<div class="card no-padding">
  <img class="campaign__image" src="{{ item.image }}" />
  <div class="u-padding-1 campaign__content">
    <h3
      class="txt-bold u-padding-bottom-10"
      [innerHTML]="item.title | translate"
    ></h3>
    <p [innerHTML]="item.overviewDescription | translate"></p>
    <br />
    <p [innerHTML]="item.amountDescription | translate"></p>
  </div>
  <div class="u-padding-1 btn-container center bg-primary">
    <app-button (buttonPressed)="onClick()" [isDisabled]="false">
      <div class="btn-with-img-container">
        <i class="far fa-heart icon-button"></i>
        <p>{{ 'BUTTON.campaign-card' | translate }}</p>
      </div>
    </app-button>
  </div>
</div>
<ng-template #popup>
  <div class="popup-container">
    <div class="content">
      <div class="level">
        <h2 class="level-left">
          {{ 'CHILDGIFTS-overview.MODAL.title' | translate }}
        </h2>
        <a (click)="closeModal()" class="mb-1">
          <i class="level-right fas fa-times icon large txt-primary"></i>
        </a>
      </div>
      <div
        class="pt-2 pb-4"
        [innerHTML]="'CHILDGIFTS-overview.MODAL.description' | translate"
      ></div>
      <div class="select u-width-100">
        <select id="childSelect" [(ngModel)]="this.item.childId" class="u-width-100">
          <option hidden value="">
            {{ 'CHILDGIFTS-overview.MODAL.select.placeholder' | translate }}
          </option>
          <option
            *ngFor="let commitment of commitments"
            [value]="commitment.beneficiaryId"
          >
            {{ commitment.name }}
          </option>
        </select>
      </div>
      <div class="btn-container end pt-4">
        <app-button
          (buttonPressed)="onModalButtonClick()"
          [isDisabled]="!this.item.childId"
          isCTA="true"
        >
          {{ 'CHILDGIFTS-overview.MODAL.button' | translate }}
        </app-button>
      </div>
    </div>
  </div>
</ng-template>
