import {Directive, Input, Output, ElementRef, HostBinding, HostListener, EventEmitter} from '@angular/core';
import {OverlayProperties} from './overlay/interfaces';
import {TooltipProperties} from './interfaces';
import {defaultProperties} from './default-properties';
import {Tooltip as TooltipService} from './tooltip.service';
import {Overlay} from './overlay/overlay.service';
import {EventService as OverlayEventService} from './overlay/event.service';

@Directive({
    selector: '[tooltipTriggerFor], [tooltip]'
})

export class TooltipTriggerDirective {
    hideTimeoutId: number;
    destroyTimeoutId: number;
    createTimeoutId: number;
    showTimeoutId: number;
    globalEventsSubscription;

    get componentRef() {
        return this.overlay.componentRefs[0];
    }

    get isTooltipDestroyed() {
        return this.componentRef && this.componentRef.hostView.destroyed;
    }

    @Input() tooltipTriggerFor;
    @Input() tooltip;
    
    @Input() tooltipClass: TooltipProperties['tooltipClass'];
    @Input() hideDelay: TooltipProperties['hideDelay'];
    @Input() showDelay: TooltipProperties['showDelay'];
    @Input() trigger: TooltipProperties['trigger'] = "hover";
    @Input() pointerEvents: TooltipProperties['pointerEvents'];
    @Input() display: TooltipProperties['display'] = true;
    
    // Animation
    @Input() animation: TooltipProperties['animation'];
    @Input() animationDuration: TooltipProperties['animationDuration'];
    @Input() animationTimingFunction: TooltipProperties['animationTimingFunction'];
    @Input() animationTranslateY: TooltipProperties['animationTranslateY'];

    // Position 
    @Input() placement: TooltipProperties['placement'];
    @Input() autoPlacement: TooltipProperties['autoPlacement'];
    @Input() position: TooltipProperties['position'];
    @Input() zIndex: TooltipProperties['zIndex'];

    // Sizes
    @Input() width: TooltipProperties['width'];
    @Input() height: TooltipProperties['height'];
    @Input() maxWidth: TooltipProperties['maxWidth'];
    @Input() minWidth: TooltipProperties['minWidth'];
    @Input() whiteSpace: TooltipProperties['whiteSpace'];

    // Styles
    @Input() theme: TooltipProperties['theme'];
    @Input() offset: TooltipProperties['offset'];
    @Input() padding: TooltipProperties['padding'];
    @Input() noArrow: TooltipProperties['noArrow'];
    @Input() borderRadius: TooltipProperties['borderRadius'];
    @Input() shadow: TooltipProperties['shadow'];
    @Input() fontSize: TooltipProperties['fontSize'];


    @Output() events: EventEmitter < any > = new EventEmitter < any > ();
    

    @HostListener('click', ['$event'])
    onClick(event) {
        if (this.trigger != 'click' || !this.display) {
            return;
        }

        this.load(event);
    }

    @HostListener('focusin', ['$event'])
    @HostListener('mouseenter', ['$event'])
    onMouseEnter(event) {
        if (this.trigger != 'hover' || !this.display) {
            return;
        }

        if (this.tooltipService.checkEventForUniqueness(event)) {
            this.load(event);
        } else {
            if (!this.componentRef || this.isTooltipDestroyed) {
                this.load(event);
            } else if (!this.isTooltipDestroyed) {
                this.tooltipService.clearTimeouts(event);
            }
        }
    }

    @HostListener('focusout')
    @HostListener('mouseleave')
    onMouseLeave() {
        if (this.trigger != 'hover') {
            return;
        }

        this.tooltipService.destroyTooltip();
    }

    constructor(
        private elementRef: ElementRef,
        public tooltipService: TooltipService, 
        public overlay: Overlay,
        private overlayEventService: OverlayEventService) {

        this.globalEventsSubscription = this.overlayEventService.emitter.subscribe(
            (event) => {
                this.handleGlobalEvents(event);
            }
        );
    }

    ngOnDestroy() {
        this.tooltipService.close();
    }

    load(event) {
        this.tooltipService.load({
            event,
            element: this.getTooltipElement(),
            tooltipText: this.tooltip,
            width: this.width,
            height: this.height,
            maxWidth: this.maxWidth,
            minWidth: this.minWidth,
            placement: this.placement,
            autoPlacement: this.autoPlacement,
            tooltipClass: this.tooltipClass,
            theme: this.theme,
            offset: this.offset,
            animationDuration: this.animationDuration,
            animationTimingFunction: this.animationTimingFunction,
            animationTranslateY: this.animationTranslateY,
            padding: this.padding,
            zIndex: this.zIndex,
            noArrow: this.noArrow,
            hideDelay: this.hideDelay,
            showDelay: this.showDelay,
            trigger: this.trigger,
            position: this.position,
            whiteSpace: this.whiteSpace,
            borderRadius: this.borderRadius,
            animation: this.animation,
            shadow: this.shadow,
            pointerEvents: this.pointerEvents,
            fontSize: this.fontSize
        });
    }

    getTooltipElement() {
        if (typeof this.tooltip === 'string') {
            return undefined;
        }

        let element = this.tooltipTriggerFor;

        if (this.tooltipTriggerFor.elementRef) {
            element = this.tooltipTriggerFor.elementRef.nativeElement;
        }

        return element;
    }

    handleGlobalEvents(event) {
        if (event.type === "Show" ||
            event.type === "Shown" ||
            event.type === "Hide" ||
            event.type === "Hidden") {

            this.events.emit(event);
        }
    }
}