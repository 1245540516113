<app-card [item]="item">
  <h3 class="txt-bold u-padding-bottom-10" [innerHTML]="item.title | translate"></h3>
  <div [class]="line.class" *ngFor="let line of item.content; let last = last">
    <ng-container *ngIf="line.value && isString(line.value)">{{ line.value | translate }}
    </ng-container>
    <ng-container *ngIf="line.value && !isString(line.value)">{{
      line.value.translate | translate: { value: line.value.value }
      }}</ng-container>
  </div>

  <ng-container *ngFor="let link of item.links">
    <div class="u-padding-top-10" *ngIf="link.route">
    <span class="icon has-text-link">
      <i class="fa fa-chevron-right"></i>
    </span>
    <a class=" has-text-link" [routerLink]="link.route">{{ link.linkText | translate }}</a>
  </div>
  </ng-container>
</app-card>
