import { Component } from '@angular/core';
import { PopupService } from '../../services/popup.service';

@Component({
  selector: 'app-popup-content',
  templateUrl: './popup-content.component.html',
  styleUrls: ['./popup-content.component.scss'],
})
export class PopUpContentComponent {
  constructor(private popupService: PopupService) {}

  public clickedOutside(event: MouseEvent) {
    let shouldSkip = false;
    event.composedPath().forEach(eventItem => {
      // @ts-ignore
      if (eventItem.id === 'full-pop-up') {
        shouldSkip = true;
      }
    });
    if (shouldSkip) {
      return;
    }

    this.popupService.clickedOutside();
  }
}
