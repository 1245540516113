import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * In order to make a new popover you need to make a new component in this folder and add a case to the switch case.
 * there are examples in the folder. Look at AttachmentInfoComponent or InboxInfoComponent.
 *
 * @export
 * @class TooltipComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent implements OnInit {
  /**
   * Location of the Popover
   *
   * @memberof TooltipComponent
   */
  @Input() location = '';

  /**
   * Only used in write letter
   * to provide confirmation to who a card is send
   */
  @Input() name = '';

  /**
   * send true to change the i to white circle and
   * primary color
   *
   * @memberof TooltipComponent
   */
  @Input() inverted = false;

  /**
   * For storing the selected component
   *
   * @type {*}
   * @memberof TooltipComponent
   */
  component: any;

  /**
   * custom css class
   *
   * @memberof TooltipComponent
   */
  cssClass = 'inbox';

  toolTipText: string;

  /**
   * Creates an instance of TooltipComponent.
   * @memberof TooltipComponent
   */
  constructor(private translateService: TranslateService) { }

  /**
   * Creates the translate string for the popover
   *
   * @memberof TooltipComponent
   */
  ngOnInit(): void {
    switch (this.location) {
      case 'inbox':
        this.toolTipText = this.translateService.instant('TOOLTIP.inbox', {
          commitmentName: this.name,
        });
        break;
      case 'payments-overview-left':
        this.toolTipText = this.translateService.instant(
          'TOOLTIP.payments-overview-left'
        );
        break;
      case 'payments-overview-right':
        this.toolTipText = this.translateService.instant(
          'TOOLTIP.payments-overview-right'
        );
        break;
      case 'gegevens':
        this.toolTipText = this.translateService.instant(
          'TOOLTIP.profile.profileInfo'
        );
        break;
      case 'betaal':
        this.toolTipText = this.translateService.instant(
          'TOOLTIP.profile.paymentInfo'
        );
        break;
      case 'adres':
        this.toolTipText = this.translateService.instant(
          'TOOLTIP.profile.addressInfo'
        );
        break;
      case 'voorkeuren':
        this.toolTipText = this.translateService.instant(
          'TOOLTIP.profile.settings'
        );
        break;
      case 'sponsorchildinfo':
        this.toolTipText = this.translateService.instant(
          'TOOLTIP.sponsorchildinfo'
        );
        break;
      case 'sent':
        this.toolTipText = this.translateService.instant('TOOLTIP.sent', {
          commitmentName: this.name,
        });
        break;
      case 'attachment':
        this.toolTipText = this.getAttachmentTooltipText();
        break;
      case 'sponsorchildpicinfo':
        this.toolTipText = this.translateService.instant(
          'TOOLTIP.sponsorchildpicinfo'
        );
        break;
      case 'regioninfo':
        this.toolTipText = this.translateService.instant('TOOLTIP.regioninfo');
        break;
      case 'tax':
        this.toolTipText = this.translateService.instant('TOOLTIP.tax');
        break;
      case 'periodical-agreement':
        this.toolTipText = this.translateService.instant('TOOLTIP.periodical-agreement');
        break;
      case 'year-tax-report':
        this.toolTipText = this.translateService.instant('TOOLTIP.year-tax-report');
        break;
      default:
        break;
    }
  }

  /**
   * Creates the multi line string for attachment popover
   *
   * @returns
   * @memberof TooltipComponent
   */
  getAttachmentTooltipText() {
    const header = this.translateService.instant('TOOLTIP.attachments.header');
    const lineOne = this.translateService.instant(
      'TOOLTIP.attachments.lineOne'
    );
    const lineTwo = this.translateService.instant(
      'TOOLTIP.attachments.lineTwo'
    );
    const lineThree = this.translateService.instant(
      'TOOLTIP.attachments.lineThree'
    );
    const lineFour = this.translateService.instant(
      'TOOLTIP.attachments.lineFour'
    );

    let result = `${header}`;
    if (lineOne) {
      result += `
      - ${lineOne}`;
    }
    if (lineTwo) {
      result += `
      - ${lineTwo}`;
    }

    if (lineThree) {
      result += `
      - ${lineThree}`;
    }

    if (lineFour) {
      result += `
      - ${lineFour}`;
    }

    return result;
  }
}
