import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PopupService {
  private isOpenSub = new BehaviorSubject<boolean>(false);

  public isOpen$ = this.isOpenSub.asObservable();

  private templateSub = new BehaviorSubject<TemplateRef<any>>(undefined);

  public template$ = this.templateSub.asObservable();

  private clickedOutsideSub = new Subject<void>();

  public clickedOutside$ = this.clickedOutsideSub.asObservable();

  public createPopup(template: TemplateRef<any>) {
    this.templateSub.next(template);
    this.isOpenSub.next(true);
  }

  public closePopup() {
    this.isOpenSub.next(false);
    this.templateSub.next(undefined);
  }

  public clickedOutside() {
    this.clickedOutsideSub.next();
  }
}
