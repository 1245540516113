import { Component, Input } from '@angular/core';
import { CampaignCardItem } from '../../interfaces/campaign-card-item.interface';
import { CardItem } from '../../interfaces/card-item';
import { ProfileCardItem } from '../../interfaces/profile-card-item.interface';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {
  /**
   * CardItem provides a {title: string, icon: string, tooltip: string}
   */
  @Input() item: CardItem | ProfileCardItem | CampaignCardItem;
}
