<div class="card" [ngClass]="{'corner-label': item.icon && item.icon !== 'none'}">
  <div *ngIf="item.icon && item.icon !== 'none'" class="corner-icon">
    <img *ngIf="item.img === 'img'" src="assets/icons/icoon-{{ item.icon }}.svg" alt="" />
    <i *ngIf="item.img === 'icon'" class="{{item.icon}} txt-white"></i>
  </div>


  <app-tooltip *ngIf="item.tooltip && item.tooltip !== 'none'" class="card-info" [location]="item.tooltip">
  </app-tooltip>

  <ng-content></ng-content>

</div>
