import { Component, Input } from '@angular/core';
/**
 * Loader Component
 *
 * @export
 * @class LoaderComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  /**
   * color of SVG
   *
   * @type {('blue' | 'white')}
   * @memberof LoaderComponent
   */
  @Input() color: 'blue' | 'white' = 'white';

  @Input() size: 'small' | 'medium' | 'large' = 'large';

  @Input() text: string = 'LOADING.loading';

  @Input() direction: 'horizontal' | 'vertical' | 'horizontal-reverse' =
    'vertical';

  @Input() withText: boolean = true;
}
