import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Angulartics2 } from 'angulartics2';
import { Subscription } from 'rxjs';
import { CampaignCardItem } from '../../interfaces/campaign-card-item.interface';
import { CommitmentPaymentItem } from '../../interfaces/payment.interface';
import { PopupService } from '../../services/popup.service';

@Component({
  selector: 'app-campaign-card',
  templateUrl: './campaign-card.component.html',
  styleUrls: ['./campaign-card.component.scss'],
})
export class CampaignCardComponent {
  @ViewChild('popup') popup: TemplateRef<any>;

  /**
   * The item that will be shown
   */
  @Input() item: CampaignCardItem;

  @Input() commitments: CommitmentPaymentItem[];

  private clickedOutsideSub: Subscription;

  constructor(
    private router: Router,
    private angulartics2: Angulartics2,
    private popupService: PopupService
  ) {}

  onClick() {
    if (!this.item.childId && this.item.childRelated) {
      if (this.commitments.length === 1) {
        this.item.childId = this.commitments[0].beneficiaryId;
        return this.goToDonatePage();
      }

      this.popupService.createPopup(this.popup);
      this.clickedOutsideSub = this.popupService.clickedOutside$.subscribe(
        () => {
          this.closeModal();
        }
      );
      return;
    }

    this.goToDonatePage();
  }

  onModalButtonClick() {
    if (!this.item.childId) {
      return;
    }
    this.closeModal();
    this.goToDonatePage();
  }

  private goToDonatePage() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        donation: this.item.slug,
        commitment: this.item.childId,
      },
    };
    this.angulartics2.eventTrack.next({
      action: 'ToDonate',
      properties: {
        category: 'campaigns',
        label: this.item.slug,
      },
    });
    this.router.navigate(['donate'], navigationExtras);
  }

  closeModal() {
    this.popupService.closePopup();

    if (this.clickedOutsideSub) {
      this.clickedOutsideSub.unsubscribe();
      this.clickedOutsideSub = undefined;
    }
  }
}
