import * as moment from 'moment';
import { Commitment } from '../interfaces/commitment.interface';

/**
 * @ignore for documentation
 * "Imported" here for performance. This way it's only "imported" once.
 */
const emojiStrip = require('emoji-strip');

/**
 * Service for small helper functions
 *
 * @export
 * @class HelperService
 */
export class HelperService {
  /**
   * Returns a center image on the face with 400x400px
   *
   * @static
   * @param {string} url
   * @returns
   * @memberof ChildrenPage
   */
  public static getImage(
    url: string,
    width: string = '400',
    height: string = '400',
    content: string = 'thumb'
  ) {
    return `https://media.ci.org/w_${width},h_${height},g_face,c_${content}/ChildPhotos/Published/${
      url.split('/').reverse()[0]
    }`;
  }

  /**
   * Generate a round image of the commitments head
   *
   * @static
   * @param {Commitment} commitment
   * @returns {string}
   * @memberof HelperService
   */
  public static getRoundThumbCommitment(commitment: Commitment): string {
    const gender = commitment.BeneficiaryResponse.Gender;
    const fullImage = commitment.BeneficiaryResponse.FullBodyImageURL;
    const imageId = fullImage.split('/').reverse()[0].replace('jpg', 'png');

    return `https://media.ci.org/w_200,h_200,g_face,c_thumb,r_100,bo_10px_solid_rgb:${
      gender === 'Man' ? '068BDD' : 'E592A2'
    }/ChildPhotos/Published/${imageId}`;
  }

  /**
   * Return the number of years
   *
   * @static
   * @param {string} birthDate
   * @returns {number}
   * @memberof HelperService
   */
  public static getYears(birthDate: string): number {
    return moment()
      .set('hour', 0)
      .set('minute', 0)
      .set('seconds', 0)
      .set('milliseconds', 0)
      .diff(birthDate, 'years');
  }

  /**
   * Return the days till the Beneficiary's birthday
   *
   * @static
   * @param {string} dateString
   * @returns {number}
   * @memberof ChildCardComponent
   */
  public static getDaysToBirthDay(dateString: string): number {
    const now = moment()
      .set('hour', 0)
      .set('minute', 0)
      .set('seconds', 0)
      .set('milliseconds', 0);
    const birthDay = moment(dateString)
      .set('year', now.get('year'))
      .set('hour', 0)
      .set('minute', 0)
      .set('seconds', 0)
      .set('milliseconds', 0);

    let count = birthDay.diff(now, 'days');
    if (Math.abs(count) >= 150) {
      count = birthDay.set('year', now.get('year') + 1).diff(now, 'days');
    }
    return count;
  }

  /**
   * Check if the screen is bigger than 768
   * (Smaller than MD breakpoint)
   *
   * @static
   * @returns {boolean}
   * @memberof HelperService
   */
  public static isMobile(): boolean {
    return window.innerWidth < 768;
  }

  /**
   * Helps in filteringout emojis from the text
   * this will only help in removing emojis when we use ctrl+cmd+space.
   * @static
   * @param {string} letterText
   * @returns {string}
   * @memberof HelperService
   */
  public static getTextWithoutEmojis(
    letterText: string
  ): { emojiString: string; isContainEmoji: boolean } {
    const emojiString = emojiStrip(letterText);
    const isContainEmoji = emojiString !== letterText;
    return { emojiString, isContainEmoji };
  }

  /**
   * Check if the iban is valid
   *
   * @static
   * @param {string} iban
   * @returns {boolean}
   * @memberof HelperService
   */
  public static checkIban(iban: string): boolean {
    const CODE_LENGTHS = {
      AD: 24,
      AE: 23,
      AT: 20,
      AZ: 28,
      BA: 20,
      BE: 16,
      BG: 22,
      BH: 22,
      BR: 29,
      CH: 21,
      CR: 21,
      CY: 28,
      CZ: 24,
      DE: 22,
      DK: 18,
      DO: 28,
      EE: 20,
      ES: 24,
      FI: 18,
      FO: 18,
      FR: 27,
      GB: 22,
      GI: 23,
      GL: 18,
      GR: 27,
      GT: 28,
      HR: 21,
      HU: 28,
      IE: 22,
      IL: 23,
      IS: 26,
      IT: 27,
      JO: 30,
      KW: 30,
      KZ: 20,
      LB: 28,
      LI: 21,
      LT: 20,
      LU: 20,
      LV: 21,
      MC: 27,
      MD: 24,
      ME: 22,
      MK: 19,
      MR: 27,
      MT: 31,
      MU: 30,
      NL: 18,
      NO: 15,
      PK: 24,
      PL: 28,
      PS: 29,
      PT: 25,
      QA: 29,
      RO: 24,
      RS: 22,
      SA: 24,
      SE: 24,
      SI: 19,
      SK: 24,
      SM: 27,
      TN: 24,
      TR: 26,
    };
    const newIban = String(iban)
      .toUpperCase()
      .replace(/[^A-Z0-9]/g, ''); // keep only alphanumeric characters
    const code = newIban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/); // match and capture (1) the country code, (2) the check digits, and (3) the rest
    // check syntax and length
    if (!code || newIban.length !== CODE_LENGTHS[code[1]]) {
      return false;
    }
    // rearrange country code and check digits, and convert chars to ints
    const digits = (code[3] + code[1] + code[2]).replace(
      /[A-Z]/g,
      // @ts-ignore
      (letter) => letter.charCodeAt(0) - 55
    );
    // final check
    let checksum: string | number = digits.slice(0, 2);
    let fragment;

    for (let offset = 2; offset < digits.length; offset += 7) {
      fragment = String(checksum) + digits.substring(offset, offset + 7);
      checksum = parseInt(fragment, 10) % 97;
    }

    return checksum === 1;
  }

  /**
   * Checks a string for emails. Removes the email and returns
   * a string without email and a status if the string included emails
   *
   * @static
   * @param {string} text
   * @returns {{ newText: string; containsEmail: boolean }}
   * @memberof HelperService
   */
  public static getTextWithoutEmail(
    text: string
  ): { newText: string; containsEmail: boolean } {
    const mailRegexString = '\\S+@\\S+\\.\\S+';
    const mailRegex = new RegExp(mailRegexString, 'g');

    let containsEmail = false;
    let newText = text;
    if (text.match(mailRegex)) {
      containsEmail = true;
      newText = text.replace(mailRegex, '');
    }
    return { newText, containsEmail };
  }
}
