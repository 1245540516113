import {
  Component,
  HostListener,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import {
  DestinationIcon,
  DestinationPickList,
  PaymentsOverviewItem,
  RecurringPaymentsOverviewItem,
} from '../../interfaces/payment.interface';
import { DateService } from '../../services/date.service';
import { WordpressService } from '../../services/wordpress/wordpress.service';

@Component({
  selector: 'app-payments-overview',
  templateUrl: './payments-overview.component.html',
  styleUrls: ['./payments-overview.component.scss'],
})
export class PaymentsOverviewComponent implements OnChanges, OnInit {
  public isInteger = Number.isInteger;

  /**
   * List of recurringPayments
   *
   * @type {recurringPayments[]}
   * @memberof PaymentsOverview
   */
  @Input() recurringPayments: RecurringPaymentsOverviewItem[];

  // /** List of oneTimePaymentsPerYear */
  @Input() oneTimePaymentsPerYear: PaymentsOverviewItem[][];

  /**
   * Determines if list is shown
   *
   * @type {boolean}
   * @memberof PaymentsOverview
   */
  continuesGiftsExpanded = true;

  /**
   * Determines if list is shown
   *
   * @type {boolean}
   * @memberof PaymentsOverview
   */
  OneTimeGiftsExpanded = true;

  /**
   * Checks if window is mobile size
   *
   * @type {boolean}
   * @memberof PaymentsOverview
   */
  isMobile = window.innerWidth < 768;

  /**
   * limit of showed payments in list
   */
  paymentListLimit = { limit: 3, index: null };

  /** expand child payments. Start with first child when initiating */
  expandedLeft = 0;

  /** expand payments per year. Start with this year when initiating */
  expandedRight = new Date().getFullYear();

  /** Formats the date */
  formatDate = DateService.formatDate;

  /** TypeScript at it's finest */
  overviewIcons: Partial<
    {
      [key in DestinationPickList]: DestinationIcon;
    }
  > = {};

  loading = true;

  isPrinting = false;

  constructor(
    private router: Router,
    private wordpressService: WordpressService
  ) {}

  /**
   * Will get the requested icons used for the list view
   */
  ngOnInit() {
    this.wordpressService.getDestinationIcons().subscribe((result) => {
      const neededIcons: DestinationPickList[] = [
        'Child Support',
        'Birthday Gift',
        'Family Gift',
        'Other',
      ];
      const usedIcons: DestinationPickList[] = [];
      result.forEach((item) => {
        const index = neededIcons.indexOf(item.pickList);

        if (index >= 0) {
          usedIcons.push(neededIcons[index]);
          this.overviewIcons[neededIcons[index]] = item.images;
        }
      });

      if (neededIcons.length !== usedIcons.length) {
        const otherIndex = result.findIndex(
          (item) => item.pickList === 'Other'
        );
        // Some icons are missing
        neededIcons.forEach((icon) => {
          const index = usedIcons.indexOf(icon);

          if (index < 0 && otherIndex >= 0) {
            // Icon is missing and other is known
            this.overviewIcons[icon] = result[otherIndex].images;
          }
        });
      }

      this.loading = false;
    });
  }

  /**
   * show more toggle left side
   */
  togglePaymentPerChild(index) {
    if (this.expandedLeft === index) {
      this.expandedLeft = -1;
    } else {
      this.expandedLeft = index;
    }
  }

  /**
   show more toggle right side
   */
  togglePaymentPerYear(year) {
    if (this.expandedRight === year) {
      this.expandedRight = -1;
    } else {
      this.expandedRight = year;
    }
  }

  /**
   * Added 3 to the limit of the payments
   *
   */
  loadMore(id) {
    this.paymentListLimit.limit += 3;
    this.paymentListLimit.index = id;
  }

  /**
   *
   *
   * @param {RecurringPaymentsOverviewItem} recurringPayments
   * @param {string} type
   * @returns
   * @memberof PaymentsOverviewComponent
   */

  static donationsInsight(recurringPayments: PaymentsOverviewItem[]) {
    const payments = {
      Familiegift: 0,
      Verjaardagsgift: 0,
      'Losse Gift': 0,
      Sponsorbijdrage: 0,
    };
    recurringPayments.forEach((rPayments) => {
      if (!payments[rPayments.type]) {
        payments[rPayments.type] = rPayments.amount;
      } else {
        payments[rPayments.type] += rPayments.amount;
      }
    });

    const result = {};

    Object.keys(payments).forEach((key) => {
      const amount = payments[key];
      result[key] = amount;
    });

    return result;
  }

  ngOnChanges() {
    if (this.recurringPayments) {
      this.recurringPayments = [
        ...this.recurringPayments.map((recurringPayments) => {
          const donations = PaymentsOverviewComponent.donationsInsight(
            recurringPayments.payments
          );

          return { ...recurringPayments, donations };
        }),
      ];
    }
  }

  // eslint-disable-next-line class-methods-use-this
  setNextDate(nextDate) {
    const dayInMonth = moment(nextDate).format('D');
    if (Number(dayInMonth) < 15) {
      return 'PAYMENTS.startMonth';
    }
    return 'PAYMENTS.endMonth';
  }

  // eslint-disable-next-line class-methods-use-this
  setMonth(nextDate) {
    return moment(nextDate).format('MMMM');
  }

  birthdayDonation(beneficiaryId: string) {
    this.router.navigate(['/donate'], {
      queryParams: {
        donation: 'verjaardag',
        commitment: beneficiaryId,
      },
    });
  }

  familyDonation(beneficiaryId: string) {
    this.router.navigate(['/kindgiften'], {
      queryParams: {
        id: beneficiaryId,
      },
    });
  }

  @HostListener('window:beforeprint', ['$event'])
  onBeforePrint(event) {
    this.isPrinting = true;
  }

  @HostListener('window:afterprint', ['$event'])
  onAfterPrint(event) {
    this.isPrinting = false;
  }
}
