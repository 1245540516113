import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Subscription, throwError } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';
import { PopupService } from '../../services/popup.service';
import { UserService } from '../../services/user/user.service';

/**
 *
 *
 * @export
 * @class ReferalPage
 * @implements {OnInit}
 */
@Component({
  selector: 'app-referal',
  templateUrl: './referal.page.html',
  styleUrls: ['./referal.page.scss'],
})
export class ReferalPage implements OnInit, OnDestroy {
  /**
   * Array with all the subscriptions to unsub from whe the page leaves
   *
   * @private
   * @type {Subscription[]}
   * @memberof ReferalPage
   */
  private subscriptions: Subscription[] = [];

  alert: {
    title: string;
    message: string;
    okButton: { text: string; handler: () => void };
    forgotButton: { text: string; handler: () => void };
  } = {
    title: '',
    message: '',
    okButton: undefined,
    forgotButton: undefined,
  };

  @ViewChild('alertpopup') alertpopup: any;

  isLoading: boolean = true;

  /**
   * Creates an instance of ReferalPage.
   * @param {UserService} userService
   * @param {ActivatedRoute} route
   * @param {Router} router
   * @memberof ReferalPage
   */
  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private popupService: PopupService
  ) {}

  /**
   * Check if the hash is valid or not
   *
   * @memberof ReferalPage
   */
  ngOnInit() {
    const hashCheck$ = this.route.queryParams.pipe(
      shareReplay(1),
      switchMap(params => {
        if (params && params.id) {
          return this.userService.autoOnboarding(params.id);
        }

        return throwError({ status: 10 });
      })
    );

    this.subscriptions.push(
      hashCheck$.subscribe(
        ({ email, crypto }) => {
          const navigationExtras: NavigationExtras = {
            queryParams: {
              data: crypto,
              email,
            },
            state: {
              email,
            },
          };
          this.isLoading = false;
          this.router.navigate(['referalPassword'], navigationExtras);
        },
        err => {
          let type: 'defaultError' | 'alreadyExists' | 'noHash';
          switch (err.status) {
            case 410:
              // 410: User already registered
              type = 'alreadyExists';
              break;
            case 10:
              type = 'noHash';
              break;
            case 400:
            case 401:
            case 500:
            case 417:
            case 426:
            default:
              /*
               * 400: hash is wrong
               * 401: id from hash is wrong
               * 500: SF error or Mongo error
               * 417: None/more contacts found in SF
               * 426: No MyCom access
               */
              type = 'defaultError';
              break;
          }

          if (type === 'noHash') {
            this.router.navigateByUrl('/');
          }

          this.alert.title = `REFERAL.${type}.title`;
          this.alert.message = `REFERAL.${type}.message`;
          this.alert.okButton = {
            text: `REFERAL.${type}.button`,
            handler: () => {
              this.router.navigateByUrl('/');
            },
          };

          if (type === 'alreadyExists') {
            this.alert.forgotButton = {
              text: `REFERAL.${type}.resetButton`,
              handler: () => {
                this.router.navigateByUrl('/forgetPassword');
              },
            };
          }

          this.isLoading = false;
          this.popupService.createPopup(this.alertpopup);
        }
      )
    );
  }

  clickButtonAlert(handler: () => void) {
    this.popupService.closePopup();
    handler();
  }

  /**
   * Unsubscribe from the observables
   */
  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
