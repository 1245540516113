<ng-container *ngIf="cta && !isCompact">
  <a
    [href]="targetUrl"
    [target]="cta.open_in_new_tab ? '_blank' : '_self'"
    class="banner large"
  >
    <img
      src="{{ cta.banner_image.url }}"
    />
    <div class="content-wrapper">
      <div class="content">
        <p class="txt-white txt-large txt-bold" [innerHTML]="cta.text"></p>
        <app-button
          [cssClasses]="'is-primary'"
          (buttonPressed)="clickButton($event)"
        >
          <span [innerHTML]="cta.button_text"></span>
        </app-button>
      </div>
    </div>
  </a>
</ng-container>
<ng-container *ngIf="cta && isCompact">
  <a
    [href]="targetUrl"
    [target]="cta.open_in_new_tab ? '_blank' : '_self'"
    class="banner compact"
    [style.background-image]="image"
  >
    <div class="content-wrapper">
      <div class="content">
        <p class="txt-white txt-large txt-bold" [innerHTML]="cta.text"></p>
        <app-button
          [cssClasses]="'is-primary'"
          (buttonPressed)="clickButton($event)"
        >
          <span [innerHTML]="cta.button_text"></span>
        </app-button>
      </div>
    </div>
  </a>
</ng-container>
